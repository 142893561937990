// App.tsx

import React, { useEffect, useMemo, useState } from "react";
import "./index.css";
import { Navigate, Route, Router, Routes } from "react-router-dom";
import { ContextProvider } from "./components/Context";
import { AppContainer } from "./styled";
import Layout from "./layout";
import Earn from "./page/Earn";
import Game from "./page/Game";
import Friends from "./page/Friends";
import Leaderboard from "./page/Leaderboard";
import Shop from "./page/Shop";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { TonConnectUIProvider } from "@tonconnect/ui-react";
import WebApp from "@twa-dev/sdk";
import {
  createNavigator,
  useNavigatorIntegration,
} from "@tma.js/react-router-integration";
import { Toaster } from "react-hot-toast";
import LoadingCommon from "./components/Common/Loading";
import { isMobile } from "./utils/checkingMobile";
import { DashboardContainerDesKtop } from "./layout/styled";
import Year from "./page/Year";
import CountdownScreen from "./page/CountdownScreen";
import ProtectedRoute from "./page/ProtectedRoute";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
// import eruda from "eruda";
// eruda.init();

dayjs.extend(utc);
dayjs.extend(timezone);

const App: React.FC = () => {
  const tmaNavigator = useMemo(createNavigator, []);
  const [location, navigator] = useNavigatorIntegration(tmaNavigator);

  const settingDefaultMiniApp = () => {
    WebApp.isClosingConfirmationEnabled = true;
    WebApp.expand();
    WebApp.ready();
  };

  useEffect(() => {
    settingDefaultMiniApp();
    const overflow = 100;
    document.body.style.overflowY = "hidden";
    document.body.style.marginTop = `${overflow}px`;
    document.body.style.height = `${window.innerHeight + overflow}px`;
    document.body.style.paddingBottom = `${overflow}px`;
    document.body.style.pointerEvents = "none";
    window.scrollTo(0, overflow);
    setTimeout(() => {
      document.body.style.pointerEvents = "auto";
    }, 2000);
  }, []);

  const [isCountdownCompleted, setIsCountdownCompleted] =
    useState<boolean>(false);
  const handleCountdownComplete = () => {
    setIsCountdownCompleted(true);
  };

  const isUserAuthenticated = true;

  return (
    <Router location={location} navigator={navigator}>
      <ContextProvider>
        <Provider store={store}>
          <TonConnectUIProvider manifestUrl="https://app.kibble.exchange/static/tonconnect-manifest.json">
            <Toaster position="top-right" reverseOrder={false} />
            <AppContainer>
              {isMobile() ? (
                <Routes>
                  {/* {!isCountdownCompleted ? (
                    <Route
                      path="/"
                      element={
                        <CountdownScreen
                          onCountdownComplete={handleCountdownComplete}
                        />
                      }
                    />
                  ) : (
                    <Route path="/" element={<LoadingCommon />} />
                  )} */}
                  <Route path="/" element={<LoadingCommon />} />
                  <Route path="/year" element={<Year />} />
                  <Route element={<Layout />}>
                    <Route path="/earn" element={<Earn />} />
                    <Route path="/leaderboard" element={<Leaderboard />} />
                    {/* <Route
                      path="/game"
                      element={
                        <ProtectedRoute isAllowed={isUserAuthenticated}>
                          <Game />
                        </ProtectedRoute>
                      }
                    /> */}
                    <Route path="/game" element={<Game />} />
                    <Route path="/shop" element={<Shop />} />
                    <Route path="/friends" element={<Friends />} />
                  </Route>
                  <Route path="*" element={<Navigate to="/" />} />
                </Routes>
              ) : (
                <DashboardContainerDesKtop>
                  <h1>Play on your phone, please!</h1>
                  <div className="img-qr-game">
                    <img src="/img/Common/qr2.jpg" alt="game" />
                  </div>
                </DashboardContainerDesKtop>
              )}
            </AppContainer>
          </TonConnectUIProvider>
        </Provider>
      </ContextProvider>
    </Router>
  );
};

export default App;
