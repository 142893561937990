import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Earth,
  GameButtonClick,
  GameContainer,
  GameImageMain,
  GameImageUser,
  GamePoint,
  GameWrapper,
  Graphic,
  GraphicBlock,
  PointTouchStart,
} from "./styled";
import { ONI_API } from "../../services/api";
import { sha256EncryptAsync } from "../../utils/encryptData";
import { useSelector } from "react-redux";
import { useDispatch } from "../../redux/store";
import { getDataInfoUser } from "../../redux/features/userSlice";
import { ContextProviderWrapper } from "../../components/Context";
import { useDebouncedCallback } from "use-debounce";
import WebApp from "@twa-dev/sdk";

const Game = () => {
  const { infoUser } = useSelector((state: any) => state.user);
  const [pointAppearArray, setPointAppearArray] = useState<any>([]);
  const [pointState, setPointState] = useState(1);
  const [maxTabProcess, setMaxTabProcess] = useState(150);
  const [animationButton, setAnimationButton] = useState(false);
  const [tapCount, setTapCount] = useState(0);
  const [tapProgress, setTapProgress] = useState(0);
  const dispatch = useDispatch();
  const { energy, setEnergy, point, setPoint } = useContext(
    ContextProviderWrapper
  )!;
  const [skinState, setSkinState] = useState({
    skinMascot01: false,
  });

  // Send API data
  const sendDataClick = useCallback(async (data?: any) => {
    try {
      await ONI_API.sendNumOfClick(data);
    } catch (error) {
      console.error("handleSendDataClick error", error);
    }
  }, []);

  const handleSendDataClick = useCallback(async () => {
    const currentEnergy = infoUser?.energy;
    try {
      let level = Math.min(infoUser?.level || 0, 7);
      // tapCount > currentEnergy => send currentEnergy >< send tapCount
      let tapToSend = 0;
      if (tapCount > currentEnergy) {
        // get energy before send
        const { data } = await ONI_API.getInfoUser();
        tapToSend = data.energy;
      } else {
        tapToSend = tapCount;
      }
      const expsToSend = tapToSend * (level + 1);
      const encryptDataToSend = `exps=${expsToSend}&taps=${tapToSend}`;
      const signatureVerifyToSend = await sha256EncryptAsync(encryptDataToSend);
      await sendDataClick({
        exps: expsToSend,
        taps: tapToSend,
        signature: signatureVerifyToSend,
      });

      // let level = Math.min(infoUser?.level || 0, 7);
      // let exps = tapCount * (level + 1);
      // let encryptData = `exps=${exps}&taps=${tapCount}`;
      // let signatureVerify = await sha256EncryptAsync(encryptData);
      // await sendDataClick({
      //   exps: exps,
      //   taps: tapCount,
      //   signature: signatureVerify,
      // });
      // if (currentEnergy === 0 && tapCount > currentEnergy) {
      //   const remainingTaps = tapCount - currentEnergy;
      //   let remainingExps = remainingTaps * (level + 1);
      //   let remainingEncryptData = `exps=${remainingExps}&taps=${remainingTaps}`;
      //   let remainingSignatureVerify = await sha256EncryptAsync(
      //     remainingEncryptData
      //   );

      //   await sendDataClick({
      //     exps: remainingExps,
      //     taps: remainingTaps,
      //     signature: remainingSignatureVerify,
      //   });
      // }

      setTapCount(0);
      dispatch(getDataInfoUser());
    } catch (error) {
      console.error("handleSendDataClick error", error);
    }
  }, [tapCount, sendDataClick, infoUser, energy]);

  const debounced = useDebouncedCallback(async () => {
    handleSendDataClick();
  }, 2000);

  // Check taps
  const handleClick = useCallback(
    (e: any, setAnimation: React.Dispatch<React.SetStateAction<boolean>>) => {
      WebApp.HapticFeedback.impactOccurred("light");
      if (!infoUser) return;
      if (energy < 1) {
        return;
      }
      const level = Math.min(infoUser?.level || 0, 7);
      const pointIncrement = level + 1;
      setTapCount((prevTapCount) => prevTapCount + 1);
      setAnimation(true);
      setEnergy((prevEnergy: any) => Math.max(prevEnergy - 1, 0));
      setPoint((prevPoint: any) => Math.max(prevPoint + pointIncrement, 0));
      const newPoint: any = [...pointAppearArray];
      if (e.touches) {
        for (let i = 0; i < e.touches.length; i++) {
          const touch = e.touches[i];
          const x = touch.clientX;
          const y = touch.clientY;
          newPoint.push({ id: Date.now() + i, text: pointIncrement, x, y });
        }
      } else {
        const x = e.clientX;
        const y = e.clientY;
        newPoint.push({ id: Date.now(), text: pointIncrement, x, y });
      }
      setPointAppearArray(newPoint);

      setTimeout(() => setAnimation(false), 50);
      debounced();
    },
    [energy, handleSendDataClick, infoUser, pointAppearArray]
  );

  useEffect(() => {
    if (tapCount !== 0 && tapCount % 100 === 0) {
      handleSendDataClick();
    }
  }, [tapCount]);

  // Handle skin check
  useEffect(() => {
    if (infoUser?.skin_id) {
      const currentSkin = infoUser.skin_url?.image_shirt;
      setSkinState({
        skinMascot01:
          currentSkin ===
            "https://s3.ap-northeast-1.amazonaws.com/wicswap/cc8f2cca-c92f-47f1-9da5-03b4fcacaab5.png" ||
          currentSkin ===
            "https://s3.ap-northeast-1.amazonaws.com/wicswap/ff6661b8-fdbb-46db-a513-eefde5b9244f.png",
      });
    }
  }, [infoUser]);

  useEffect(() => {
    const intervalPointAppearArray = setInterval(() => {
      setPointAppearArray([]);
    }, 3000);
    return () => {
      clearInterval(intervalPointAppearArray);
    };
  }, []);

  return (
    <GameContainer className="animate__animated animate__fadeIn">
      <GameWrapper>
        {pointAppearArray.map((item: any) => {
          return (
            <PointTouchStart
              style={{
                position: "absolute",
                top: item.y - 150,
                left: item.x,
                transform: "translate(-50%, -50%)",
                pointerEvents: "none",
                zIndex: 100,
                opacity: 0,
              }}
              key={item.id}
            >
              +{item.text}
            </PointTouchStart>
          );
        })}
        <Earth>
          <img src="/img/Game/game_gif.gif" alt="" />
        </Earth>
        {/* <GamePoint>
          <span className="point">x{pointState}</span>
        </GamePoint> */}
        <GameImageMain>
          <img src="/img/Game/game_img_04.png" width={430} alt="" />
        </GameImageMain>
        <GraphicBlock>
          <Graphic>
            <img src="/img/Game/game_img_05.png" width={53} alt="" />
          </Graphic>
        </GraphicBlock>
        <GameImageUser onTouchStart={(e) => handleClick(e, setAnimationButton)}>
          <div
            className={`left-hand ${
              skinState.skinMascot01 ? "left-hand-different" : ""
            }`}
            style={{ bottom: animationButton ? "-22px" : "-17px" }}
          >
            <img
              src={
                infoUser?.skin_url !== "No skin yet"
                  ? infoUser?.skin_url?.image_hand_left
                  : "/img/Common/left_hand.png"
              }
              width={92}
              alt="left hand"
            />
          </div>
          <img
            src={infoUser?.skin_url?.image_shirt || "/img/Game/game_img_03.png"}
            width={227}
            alt="user"
            className="img-main"
            style={{
              bottom: animationButton
                ? "-75px"
                : skinState.skinMascot01
                ? "-67px"
                : "-70px",
            }}
          />
          <div
            className={`right-hand ${
              skinState.skinMascot01 ? "right-hand-different" : ""
            }`}
            style={{ bottom: animationButton ? "-22px" : "-17px" }}
          >
            <img
              src={
                infoUser?.skin_url !== "No skin yet"
                  ? infoUser?.skin_url?.image_hand_right
                  : "/img/Common/right_hand.png"
              }
              width={92}
              alt="right hand"
            />
          </div>
        </GameImageUser>
        <GameButtonClick
          onTouchStart={(e) => handleClick(e, setAnimationButton)}
        >
          <img
            src="/img/Game/game_button.png"
            width={188}
            alt=""
            style={{ height: animationButton ? "135px" : "154px" }}
          />
        </GameButtonClick>
      </GameWrapper>
    </GameContainer>
  );
};

export default Game;
