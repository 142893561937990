import { configureStore } from "@reduxjs/toolkit";
import { useDispatch as useAppDispatch } from "react-redux";
import pointReducer from "./features/pointSlice";
import { authReducer } from "./features/authSlice";
import userReducer from "./features/userSlice";

export const store = configureStore({
  reducer: {
    point: pointReducer,
    auth: authReducer,
    user: userReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

const useDispatch = () => useAppDispatch<AppDispatch>();

export { useDispatch };
