import styled from "styled-components";
import { HeaderInfo } from "../../components/Header/styled";
import { LayoutCommon } from "../../layout/styled";
import { TitleContainer } from "../../components/Common/Title/styled";

export const LeaderboardContainer = styled.div`
  ${LayoutCommon} {
    min-height: unset;
    &:nth-child(1) {
      padding-right: 0;
      width: calc(100% - 40px);
      padding-left: 40px;
      .image-decor {
        position: absolute;
        right: -15px;
        top: -19px;
      }
    }
  }
  ${TitleContainer} {
    padding: 15px 0;
    margin-bottom: 10px;
  }
  ${HeaderInfo} {
    .name-leaderboard {
      -webkit-text-stroke-width: 4px !important;
      text-shadow: unset !important;
      paint-order: stroke fill;
    }
  }
  .block-leaderboard {
    height: calc(100vh - 340px);
    overflow: auto;
  }
`;
export const LeaderboardHeading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const LeaderboardRank = styled.div`
  border-radius: 69px 0 0 69px;
  background: var(--Mint-Green-300, #81ee97);
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 6px 10px;
  .content-rank {
    .text {
      color: var(--Native-text_color, #000);
      font-size: 11px;
      font-style: normal;
      font-weight: 900;
      letter-spacing: -0.44px;
    }
    .position {
      color: #fff;
      -webkit-text-stroke-width: 2px;
      -webkit-text-stroke-color: var(--Native-text_color, #000);
      font-size: 16px;
      line-height: 100%; /* 16px */
      text-align: center;
      paint-order: stroke fill;
    }
  }
`;

export const BlockLeaderboard = styled.div``;
export const ListLeaderboard = styled.ul`
  border-top: solid 2px #bbffb2;
  padding-bottom: 10px;
  height: 100%;
  overflow: auto;
  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid 2px #bbffb2;
    padding: 14px 0 14px 12px;
    width: calc(100% - 12px);
    overflow: hidden;
    &:nth-child(1) {
      .content-left {
        .position {
          &:before {
            background-image: url("/img/Leaderboard/loaderboard_icn_05.svg");
          }
        }
      }
    }
    &:nth-child(2) {
      .content-left {
        .position {
          &:before {
            background-image: url("/img/Leaderboard/loaderboard_icn_06.svg");
          }
        }
      }
    }
    &:nth-child(3) {
      .content-left {
        .position {
          &:before {
            background-image: url("/img/Leaderboard/loaderboard_icn_07.svg");
          }
        }
      }
    }
  }
  .content-left {
    display: flex;
    align-items: center;
    gap: 3px;
    max-width: 50%;
    .position {
      color: #fff;
      text-align: center;
      text-shadow: 0px 3px 0px #000;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: var(--Native-text_color, #000);
      font-family: Poppins;
      font-size: 14px;
      font-weight: 900;
      position: relative;
      z-index: 1;
      margin-right: 14px;
      flex-shrink: 0;
      &:before {
        position: absolute;
        content: "";
        top: 50%;
        left: 50%;
        margin-top: -15px;
        margin-left: -15px;
        width: 32px;
        height: 32px;
        background: url("/img/Leaderboard/loaderboard_icn_03.svg") center /
          cover no-repeat;
        z-index: -1;
      }
    }
    .name {
      color: var(--Native-text_color, #000);
      font-family: Segoe_Bold;
      font-size: 15px;
      font-weight: 900;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .content-right {
    display: flex;
    align-items: center;
    gap: 5px;
    max-width: 50%;
    .level {
      color: #fff;
      text-align: center;
      text-shadow: 0px 3.447px 0px #000;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: var(--Native-text_color, #000);
      font-family: Poppins;
      font-size: 16.085px;
      font-weight: 900;
      position: relative;
      z-index: 3;
      flex-shrink: 0;
      width: 37px;
      right: -10px;
      &:before {
        position: absolute;
        content: "";
        top: 50%;
        left: 50%;
        margin-top: -18px;
        margin-left: -18px;
        background: url("/img/Leaderboard/loaderboard_icn_04.svg") center /
          cover no-repeat;
        width: 37px;
        height: 37px;
        z-index: -1;
      }
    }
    .point {
      min-width: 80px;
      border-radius: 0px 16px 16px 0;
      background: var(--Mint-Green-300, #81ee97);
      backdrop-filter: blur(2px);
      color: var(--Native-text_color, #000);
      text-align: center;
      font-family: Segoe_Bold;
      font-size: 14px;
      font-style: normal;
      font-weight: 900;
      letter-spacing: -1px;
      padding: 3px 10px;
      position: relative;
      z-index: 1;
    }
  }
`;
