import { Outlet } from "react-router-dom";
import { LayoutContainer, WrapperOutlet } from "./styled";
import Header from "../components/Header";
import Navigation from "../components/Navigation";
import { useSelector } from "react-redux";
import LoadingCommon from "../components/Common/Loading";

const Layout = () => {
  const { infoUser } = useSelector((state: any) => state.user);
  return (
    <>
      {infoUser ? (
        <LayoutContainer>
          <Header />
          <WrapperOutlet>
            <Outlet />
          </WrapperOutlet>
          <Navigation />
        </LayoutContainer>
      ) : (
        <LoadingCommon />
      )}
    </>
  );
};

export default Layout;
