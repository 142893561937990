const formatNumber = (num: any) => {
  if (num) {
    let number = Number(num);
    return new Intl.NumberFormat("en-US").format(number);
  } else {
    return "0";
  }
};

export const formatMiniNumber = (num: any) => {
  if(!num){
    return
  }
  let decimalStr = num?.toFixed(20);

  let dotIndex = decimalStr?.indexOf(".");

  let zeroCount = 0;
  for (let i = dotIndex + 1; i < decimalStr?.length; i++) {
    if (decimalStr[i] === "0") {
      zeroCount++;
    } else {
      break;
    }
  }

  let firstNonZeroIndex = dotIndex + 1 + zeroCount;
  let significantPart = decimalStr.substring(
    firstNonZeroIndex,
    firstNonZeroIndex + 3
  );

  let subscriptZeroCount = (zeroCount) > 0 ? (zeroCount)
    .toString()
    .split("")
    .map((c) => String.fromCharCode(8320 + parseInt(c)))
    .join("") : "";

  let integerPart = decimalStr.substring(0, dotIndex);

  if (zeroCount > 0 && significantPart) {
    return `${integerPart}.0${subscriptZeroCount}${significantPart}`;
  } else {
    return new Intl.NumberFormat("en-US").format(num);
  }
};

export const formatNumberMega = (num?: number) => {
  if (num) {
    const formatter = new Intl.NumberFormat('en-US', {
      // minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return formatter.format(num);
  } else {
    return "0";
  }
};


export default formatNumber;
