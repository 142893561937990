import React, { useState } from "react";
import {
  ModalClaimContainer,
  ModalClaimContent,
  ModalClaimImage,
  ModalClaimTitle,
} from "./styled";
import { ButtonClaim } from "../styled";
import { ONI_API } from "../../../services/api";
import toast from "react-hot-toast";
import { formatNumberMega } from "../../../utils/formatNumber";
import { getDataDailyCheckin, getDataInfoUser } from "../../../redux/features/userSlice";
import { useDispatch } from "../../../redux/store";

const ModalClaim = ({ claimedPoint, handleCancel }: any) => {
  const dispatch = useDispatch();

  // handle Claim Point
  const handleClaimPoint = async () => {
    const res = await ONI_API.getPointCheckin();
    if (res) {
      toast.success("Success");
      handleCancel();
      await dispatch(getDataDailyCheckin());
      await dispatch(getDataInfoUser());
    }
  };

  return (
    <ModalClaimContainer>
      <ModalClaimImage>
        <img src="/img/Earn/modal_img_01.png" width={360} alt="" />
      </ModalClaimImage>
      <ModalClaimContent>
        <ModalClaimTitle>
          You’ve received<span>+{formatNumberMega(claimedPoint)}</span>
        </ModalClaimTitle>
        <ButtonClaim onClick={handleClaimPoint}>Got It</ButtonClaim>
      </ModalClaimContent>
    </ModalClaimContainer>
  );
};

export default ModalClaim;
