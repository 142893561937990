import styled from "styled-components";

export const LoadingContainer = styled.div`
  position: relative;
  animation-delay: 0.5s;
`;
export const LoadingImage = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
export const LoadingBlock = styled.div<{ Progress?: any }>`
  position: absolute;
  top: 60%;
  right: 20px;
  transform: translateY(-50%);
  .text-loading {
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
  }
  .block-percent {
    width: 118px;
    height: 18px;
    border-radius: 4px;
    background: var(--Mint-Green-800, #166527);
    position: relative;
    overflow: hidden;
    &::before,
    &::after {
      transition: all 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    }
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: ${props => props.Progress}%;
      height: 100%;
      background: var(--Mint-Green-300, #81EE97);
    }
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: ${props => props.Progress}%;
      height: 9px;
      background: var(--Mint-Green-500, #22C544);
    }
  }
`
export const BlockCheck = styled.div`
  position: fixed;
  bottom: 20px;
  left: 0;
  right: 0;
  background-color: #F1FDFC;
  height: 30vh;
  opacity: 0;
  transition: opacity .13s ease;
  &.is-show {
    opacity: 1;
  }
`
export const BlockCheckList = styled.ul`
  padding: 17px;
  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 9px 0;
    border-top: solid 1px #C6DFD2;
    min-height: 35px;
    &:last-child {
      border-bottom: solid 1px #C6DFD2;
    }
  }
  .content-left,
  .content-right {
    display: flex;
    align-items: center;
  }
  .content-left {
    gap: 5px;
    img {
      position: relative;
      top: -1px;
    }
  }
  .title-label {
    color: var(--Mint-Green-800, #166527);
    font-family: Segoe_Bold;
    font-size: 12px;
    font-weight: 900;
    letter-spacing: -0.5px;
  }
  .content-right {
    position: relative;
    justify-content: flex-end;
    gap: 20px;
    width: 41%;
    img {
      order: 2;
      position: relative;
      top: 2px;
      opacity: 0;
      transition: opacity .26s ease;
      &.is-show {
        opacity: 1;
      }
    }
    ${LoadingBlock} {
      right: 40px;
    }
  }
`