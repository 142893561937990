import React, { useEffect, useState } from "react";
import {
  BlockDressing,
  BlockDressingButton,
  BlockDressingImage,
  BlockDressingLeft,
  BlockDressingRight,
  BlockItem,
  BlockItemList,
  ShopContainer,
} from "./styled";
import { LayoutCommon } from "../../layout/styled";
import TitleCommon from "../../components/Common/Title";
import { ONI_API } from "../../services/api";
import { formatNumberMega } from "../../utils/formatNumber";
import toast from "react-hot-toast";
import { Spin } from "antd";
import { useDispatch } from "../../redux/store";
import { getDataInfoUser, getDataListSkin } from "../../redux/features/userSlice";
import { useSelector } from "react-redux";

const Shop = () => {
  const { getListSkin } = useSelector((state: any) => state.user);
  const [currenImg, setCurrentImg] = useState<any>();
  const [currentPrice, setCurrentPrice] = useState();
  const [nameSkin, setNameSkin] = useState();
  const [idSkin, setIdSkin] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [checkSkinUnlock, setCheckSkinUnlock] = useState(false);
  const dispatch = useDispatch();
  const [isUseSkin, setIsUseSkin] = useState(false);

  //handle change skin
  const handleChangeSkin = (item: any) => {
    setCurrentImg(item?.thumbnail_info?.file_path);
    setCurrentPrice(item?.point);
    setNameSkin(item?.name);
    setIdSkin(item?.skin_id);
    setCheckSkinUnlock(
      item?.user_skin?.length > 0 && item?.user_skin[0]?.status === "UNLOCKED"
    );
    setIsUseSkin(
      item?.user_skin?.length > 0 && item?.user_skin[0]?.tick === "TICK"
    );
  };

  // handle check skin
  useEffect(() => {
    if (getListSkin && idSkin) {
      const currentSkin = getListSkin.find(
        (item: any) => item.skin_id === idSkin
      );
      if (currentSkin) {
        setCheckSkinUnlock(
          currentSkin?.user_skin?.length > 0 &&
            currentSkin?.user_skin[0]?.status === "UNLOCKED"
        );
        setIsUseSkin(
          currentSkin?.user_skin?.length > 0 &&
            currentSkin?.user_skin[0]?.tick === "TICK"
        );
      }
    }
  }, [getListSkin, idSkin]);

  useEffect(() => {
    if (getListSkin?.length > 0) {
      setCurrentImg(getListSkin[0]?.thumbnail_info?.file_path);
      setCurrentPrice(getListSkin[0]?.point);
      setNameSkin(getListSkin[0]?.name);
      setIdSkin(getListSkin[0]?.skin_id);
      setCheckSkinUnlock(
        getListSkin[0]?.user_skin?.length > 0 &&
          getListSkin[0]?.user_skin[0]?.status === "UNLOCKED"
      );
      setIsUseSkin(
        getListSkin[0]?.user_skin?.length > 0 &&
          getListSkin[0]?.user_skin[0]?.tick === "TICK"
      );
    }
  }, [getListSkin]);

  useEffect(() => {
    if (getListSkin?.length > 0) {
      setCurrentImg(getListSkin[0]?.thumbnail_info?.file_path);
      setCurrentPrice(getListSkin[0]?.point);
      setNameSkin(getListSkin[0]?.name);
    }
  }, [getListSkin]);

  //buy skin
  const handleBuySkin = async () => {
    setLoading(true);
    try {
      const res = await ONI_API.buySkin(idSkin);
      if (res) {
        toast.success("Buy Success");
        setLoading(false);
        dispatch(getDataInfoUser());
        dispatch(getDataListSkin());
      }
    } catch (error) {
      console.log(error);
      toast.error("Fail");
      setLoading(false);
    }
  };

  //handle choose skin
  const handleChooseSkin = async () => {
    setLoading(true);
    try {
      const res = await ONI_API.selectSkin(idSkin);
      if (res) {
        dispatch(getDataInfoUser());
        toast.success("Selected");
        dispatch(getDataListSkin());
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      toast.error("Fail");
      setLoading(false);
    }
  };

  return (
    <ShopContainer className="animate__animated animate__fadeIn">
      <LayoutCommon className="block-dressing">
        <TitleCommon text="Dressing Room" />
        <BlockDressing>
          <BlockDressingLeft>
            <BlockDressingImage>
              <div className="content-image">
                <img src={currenImg} width={68} height={68} alt="" />
              </div>
              <h3 className="title-skin">{nameSkin}</h3>
              {checkSkinUnlock ? (
                <BlockDressingButton onClick={() => handleChooseSkin()} disabled={isUseSkin ? true : false}>
                  {loading ? (
                    <Spin />
                  ) : isUseSkin ? (
                    "Skin is used"
                  ) : (
                    "Choose Skin"
                  )}
                </BlockDressingButton>
              ) : (
                <BlockDressingButton onClick={() => handleBuySkin()}>
                  {loading ? <Spin /> : formatNumberMega(currentPrice)}
                </BlockDressingButton>
              )}
            </BlockDressingImage>
          </BlockDressingLeft>
          <BlockDressingRight>
            <img src={currenImg} width={113} height={163} alt="" />
          </BlockDressingRight>
        </BlockDressing>
      </LayoutCommon>
      <LayoutCommon className="block-item">
        <TitleCommon text="Items" />
        <BlockItem>
          <BlockItemList>
            {getListSkin?.map((item: any, index: number) => (
              <li key={index} onClick={() => handleChangeSkin(item)} className={item?.user_skin[0]?.tick === 'TICK' ? 'is-used' : ''}>
                <BlockDressingImage>
                  <div className="content-image">
                    <img
                      src={item.thumbnail_info.file_path}
                      width={95}
                      height={94}
                      alt={item.name}
                    />
                  </div>
                  <h3 className="title-skin">{item.name}</h3>
                </BlockDressingImage>
                {item?.user_skin[0]?.tick === 'TICK' && <img src="/img/Shop/shop_icn_01.png" className="check-icon" width={29} height={29} alt="check" />}
              </li>
            ))}
          </BlockItemList>
        </BlockItem>
      </LayoutCommon>
    </ShopContainer>
  );
};

export default Shop;
