import styled from "styled-components";

export const ModalMissionContainer = styled.div`
  text-align: center;
`;
export const ModalMissionWrapper = styled.div`
`;

export const ModalMissionTitle = styled.h3`
  color: #fff;
  text-align: center;
  text-shadow: 0px 3px 0px #000;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #000;
  font-family: Segoe_Bold;
  font-size: 20px;
  font-weight: 900;
  margin-bottom: 10px;
`;
export const ModalMissionDescription = styled.p`
  color: #434343;
  font-family: Segoe_Bold;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: -0.44px;
  text-align: center;
  margin-bottom: 0;
`;
export const ModalMissionPoint = styled.p`
  color: #FFF;
  text-shadow: 0px 3px 0px #000;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #000;
  font-family: Segoe_Bold;
  font-size: 24px;
  font-weight: 900;
  margin-bottom: 10px;
`

export const ButtonMission = styled.a`
  display: inline-block;
  background: unset;
  border: unset;
  color: #fff;
  text-align: center;
  text-shadow: 0px 1.245px 0px #000;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 900;
  position: relative;
  z-index: 1;
  min-width: 84px;
  width: 84px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  outline: none;
  &:hover {
    color: #fff;
  }
  &:focus-visible {
    outline: none;
  }
  &:before,
  &:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    z-index: -1;
    transition: all 0.26s ease;
  }
  &:before {
    background: url("/img/Earn/button_bg.png") center / cover no-repeat;
  }
  &:after {
    opacity: 0;
    background: url("/img/Earn/button_bg_02.png") center / cover no-repeat;
  }
  &.is-claim {
    min-width: 120px;
    &:after {
      opacity: 1;
    }
  }
`;