import React, { useState } from "react";
import { YearBlockTop, YearContainer, YearWrapper } from "./styled";
import { BlockDressingButton } from "../Shop/styled";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { formatNumberMega } from "../../utils/formatNumber";

const Year = () => {
  const {infoUser} = useSelector((state:any)=> state.user)
  console.log('infoUser',infoUser)
  const navigate = useNavigate()
  const [newStep, setNewStep] = useState(false);

  const changeStep = () => {
    if (!newStep) {
      setNewStep(true);
    } else {
      handleChangeLink();
    }
  };

  const handleChangeLink = () => {
    navigate("/game");
  };

  // calculate

  return (
    <YearContainer className="animate__animated animate__fadeIn">
      <YearWrapper>
        <YearBlockTop className={newStep ? 'new-step' : ''}>
          <div className="content-top">
            <span className="number">{newStep ? formatNumberMega(Number(infoUser?.points)) : infoUser?.account_age}</span>
            <p className="text-year">{newStep ? 'ONI' : 'Year olds'}</p>
          </div>
          <p className="text-account">{newStep ? `You get the reward is ${formatNumberMega(Number(infoUser?.points))} ONI` : `Your account number is #${infoUser?.telegram_id}`}</p>
        </YearBlockTop>
        <BlockDressingButton onClick={changeStep}>{newStep ? 'Finish' : 'Continue'}</BlockDressingButton>
      </YearWrapper>
    </YearContainer>
  );
};

export default Year;
