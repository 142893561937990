import styled from "styled-components";
import { GameProgressBar } from "../Common/ProgressBar/styled";

export const HeaderContainer = styled.div`
  position: relative;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #145323;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    height: 4px;
    background: #16a333;
    box-shadow: 0px -2px 0px 0px#15802C inset;
  }
`;
export const HeaderInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: -3px;
  & > div {
    &:nth-child(1) {
      width: 60px;
      height: 60px;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        inset: -4px;
        background-color: #81ee97;
        border-radius: 10px;
      }
    }
    &:nth-child(2) {
      ${GameProgressBar} {
        width: 95px;
      }
      & > p {
        color: #fff;
        text-shadow: 0px 4px 0px #000;
        -webkit-text-stroke-width: 0;
        -webkit-text-stroke-color: #000;
        font-family: Segoe_Bold;
        font-size: 16px;
        margin-bottom: 8px;
      }
    }
  }
  figure {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    background-color: #000;
    border-radius: 8px;
    z-index: 5;
    overflow: hidden;
    img {
      /* position: relative;
      top: 2px; */
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;
export const HeaderVisual = styled.div`
  min-width: 105px;
`;
export const VisualBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 18px;
  border-radius: 20px;
  background: #297241;
  backdrop-filter: blur(2px);
  position: relative;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  figure {
    position: absolute;
    left: -12px;
    top: 50%;
    margin-top: -14px;
    width: 27px;
    height: 28px;
  }
  p {
    font-family: Segoe_Bold;
    font-size: 14px;
    color: #fff;
    span {
      font-size: 12px;
      color: #58cf7b;
    }
  }
`;
