import React, { useEffect, useState } from "react";
import {
  BlockCheck,
  BlockCheckList,
  LoadingBlock,
  LoadingContainer,
  LoadingImage,
} from "./styled";
import { useDispatch } from "../../../redux/store";
import {
  getDataDailyCheckin,
  getDataLeaderBoard,
  getDataListFriends,
  getDataListMission,
  getDataListSkin,
  getDataPointInviteFriend,
} from "../../../redux/features/userSlice";
import { useInitData } from "@tma.js/sdk-react";
import { useNavigate } from "react-router-dom";
import { ONI_API } from "../../../services/api";
import WebApp from "@twa-dev/sdk";
import { getDataInfoUser } from "../../../redux/features/userSlice";
import { useSelector } from "react-redux";

const preComponent = () => {
  import("../../../page/Game/index");
  import("../../../page/Earn/index");
  import("../../../page/Leaderboard/index");
  import("../../../page/Shop/index");
  import("../../../page/Friends/index");
  import("../../../page/Year/index");
};

const checkAllImages = () => {
  const images = [
    "/img/Common/lock_note_left.png",
    "/img/Common/lock_note_right.png",
    "/img/Common/lock_note2_right.png",
    "/img/Common/lock_note2_left.png",
    "/img/Common/title_icn.svg",
    "/img/Common/title_icn_02.png",
    "/img/Common/image_loading.png",
    "/img/Header/avatar.png",
    "/img/Header/visual_icon_1.png",
    "/img/Header/visual_icon_2.png",
    "/img/Header/progress_star.png",
    "/img/Leaderboard/loaderboard_icn_01.png",
    "/img/Leaderboard/loaderboard_icn_02.png",
    "/img/Leaderboard/loaderboard_icn_03.svg",
    "/img/Leaderboard/loaderboard_icn_04.svg",
    "/img/Leaderboard/loaderboard_icn_05.svg",
    "/img/Leaderboard/loaderboard_icn_06.svg",
    "/img/Leaderboard/loaderboard_icn_07.svg",
    "/img/Navigation/navigation_1.svg",
    "/img/Navigation/navigation_2.svg",
    "/img/Navigation/navigation_3.svg",
    "/img/Navigation/navigation_4.svg",
    "/img/Navigation/navigation_5.svg",
    "/img/Navigation/navigation_decor_effect.png",
    "/img/Navigation/navigation_decor.png",
    "/img/Navigation/space_frame_hover.png",
    "/img/Navigation/space_frame.png",
    "/img/Friends/friends_bg_01.png",
    "/img/Friends/friends_bg_02.png",
    "/img/Friends/friends_bg_03.png",
    "/img/Friends/friends_icn_01.svg",
    "/img/Friends/friends_icn_02.svg",
    "/img/Friends/friends_icn_03.png",
    "/img/Friends/friends_icn_04.png",
    "/img/Earn/earn_icn_01.svg",
    "/img/Earn/earn_bg_01.png",
    "/img/Earn/button_bg_02.png",
    "/img/Earn/button_bg.png",
    "/img/Earn/earn_img_01.png",
    "/img/Earn/modal_img_01.png",
    "/img/Shop/bg_button.png",
    "/img/Shop/block_bg.png",
    "/img/Shop/shop_img_01.png",
    "/img/Shop/shop_img_02.png",
    "/img/Shop/shop_img_03.png",
    "/img/Shop/shop_img_04.png",
    "/img/Shop/shop_img_05.png",
    "/img/Shop/shop_img_06.png",
    "/img/Shop/shop_img_07.png",
    "/img/Shop/shop_img_08.png",
    "/img/Shop/shop_img_09.png",
    "/img/Shop/shop_img_10.png",
    "/img/Shop/shop_img_11.png",
    "/img/Shop/shop_img_12.png",
    "/img/Shop/shop_img_13.png",
    "/img/Shop/shop_img_14.png",
    "/img/Shop/shop_img_15.png",
    "/img/Shop/shop_icn_01.png",
    "/img/Game/game_gif.gif",
    "/img/Game/game_img_01.png",
    "/img/Game/game_img_02.png",
    "/img/Game/game_button.png",
    "/img/Game/game_img_03.png",
    "/img/Game/game_icn_01.png",
    "/img/Game/game_img_04.png",
    "/img/Game/game_img_05.png",
    "/img/Game/game_img_06.png",
    "/img/Year/year_bg.png",
    "https://s3.ap-northeast-1.amazonaws.com/wicswap/409ebf1e-8f6f-4503-976b-36d7e7cdf3e1.png",
    "https://s3.ap-northeast-1.amazonaws.com/wicswap/57d1eb95-9a93-458c-872d-aaa8e4499451.png",
    "https://s3.ap-northeast-1.amazonaws.com/wicswap/67d04c96-91db-410d-a200-41afccb4db29.png",
    "https://s3.ap-northeast-1.amazonaws.com/wicswap/0743ae1d-cbdf-4e91-b4e1-41daa9cb5ca2.png",
    "https://s3.ap-northeast-1.amazonaws.com/wicswap/6cbcfd90-8b26-4694-bd2e-3ee5240ff4c5.png",
    "https://s3.ap-northeast-1.amazonaws.com/wicswap/258ef481-a7e9-4ede-bc7d-1dfc75c6ba2e.png",
  ];

  const preloadImage = (src: string) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(src);
      img.onerror = () => reject(src);
      img.src = src;
    });
  };

  return Promise.all(images.map(preloadImage))
    .then(() => {
      console.log("All images loaded successfully");
      return true;
    })
    .catch((failedSrc) => {
      console.error("Failed to load image:", failedSrc);
      return false;
    });
};

const LoadingCommon = () => {
  const { infoUser } = useSelector((state: any) => state.user);
  const [progress, setProgress] = useState(0);
  const [progressCheck, setProgressCheck] = useState<number[]>(
    new Array(STATUS_LIST.length).fill(0)
  );
  const [checkVerify, setCheckVerify] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLogin, setIsLogin] = useState(false);
  const [loadingComplete, setLoadingComplete] = useState(false);
  const dataUserTele: any = useInitData();
  const {
    initData: { user },
  } = dataUserTele;
  const { initDataUnsafe } = WebApp;

  // handle login with telegram
  const handleLoginWithTele = async (payload: any) => {
    try {
      const { data } = await ONI_API.loginWithTele(payload);
      await localStorage.setItem("token", data.token);
      setIsLogin(true);
    } catch (error) {
      console.log("====================================");
      console.log("handleLoginWithTele err", error);
      console.log("====================================");
      window.location.reload();
    }
  };

  const fetchData = async () => {
    try {
      await Promise.all([
        dispatch(getDataInfoUser()),
        dispatch(getDataListFriends()),
        dispatch(getDataListSkin()),
        dispatch(getDataLeaderBoard()),
        dispatch(getDataListMission()),
        dispatch(getDataDailyCheckin()),
        dispatch(getDataPointInviteFriend()),
        preComponent(),
        checkAllImages(),
      ]);
      setLoadingComplete(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  //handle login
  useEffect(() => {
    if (user) {
      const startParams = initDataUnsafe.start_param;
      const listAddressFromParams: any = startParams?.split("=");
      const payload = {
        telegram_id: `${user.id}`,
        telegram_name: `${user.firstName} ${user.lastName}`,
        referral_code:
          listAddressFromParams && listAddressFromParams.length > 0
            ? listAddressFromParams[0]
            : "",
        is_premium: user.isPremium,
        telegram_username: user.username ? `${user.username}` : "",
      };
      handleLoginWithTele(payload);
    }
  }, [user]);

  useEffect(() => {
    if (isLogin) {
      fetchData();
    }
  }, [isLogin]);

  // text
  useEffect(() => {
    if (loadingComplete && infoUser) {
      if (!infoUser?.is_verified) {
        setCheckVerify(true);
      } else {
        setCheckVerify(false);
      }
    }
  }, [loadingComplete, infoUser]);

  useEffect(() => {
    const duration = 3200;
    const interval = 50;
    let timer: NodeJS.Timeout;

    const animate = (startTime: number) => {
      const elapsedTime = Date.now() - startTime;
      const newProgress = Math.min((elapsedTime / duration) * 100, 100);
      setProgress(Math.round(newProgress));

      if (loadingComplete) {
        setProgress(100);
        if (infoUser?.is_verified) {
          setTimeout(() => navigate("/game"), 100);
        }
      } else {
        setProgress(Math.round(newProgress));

        if (newProgress < 100) {
          timer = setTimeout(() => animate(startTime), interval);
        }
      }
    };

    animate(Date.now());

    return () => clearTimeout(timer);
  }, [loadingComplete]);

  // handle verify
  useEffect(() => {
    if (checkVerify && progress === 100) {
      let index = 0;

      const updateProgressCheck = () => {
        if (index >= STATUS_LIST.length) {
          setTimeout(() => {
            navigate("/year");
          }, 100);
          return;
        }

        const interval = setInterval(() => {
          setProgressCheck((prev) => {
            const newProgressCheck = [...prev];
            newProgressCheck[index] += 10;

            if (newProgressCheck[index] >= 100) {
              newProgressCheck[index] = 100;
              clearInterval(interval);
              index += 1;
              updateProgressCheck();
            }

            return newProgressCheck;
          });
        }, 100);
      };
      updateProgressCheck();
    }
  }, [progress, checkVerify]);

  return (
    <LoadingContainer className="animate__animated animate__fadeIn">
      <LoadingImage>
        <img src="/img/Common/image_loading.png" width={428} alt="" />
      </LoadingImage>
      <LoadingBlock Progress={progress}>
        <p className="text-loading">Loading...{progress}%</p>
        <div className="block-percent"></div>
      </LoadingBlock>
      <BlockCheck className={checkVerify ? "is-show" : ""}>
        <BlockCheckList>
          {STATUS_LIST.map((item: any, index: number) => (
            <li key={index}>
              <div className="content-left">
                <img src="/img/Common/icn_star.png" width={28} alt="Star" />
                <h3 className="title-label">{item.label}</h3>
              </div>
              <div className="content-right">
                <LoadingBlock Progress={progressCheck[index]}>
                  <div className="block-percent"></div>
                </LoadingBlock>
                <img
                  src="/img/Common/icn_check.png"
                  className={progressCheck[index] === 100 ? "is-show" : ""}
                  width={32}
                  alt="Check"
                />
              </div>
            </li>
          ))}
        </BlockCheckList>
      </BlockCheck>
    </LoadingContainer>
  );
};

const STATUS_LIST = [
  {
    label: "Account Age Verified",
    percent: "100%",
  },
  {
    label: "Activity Level Analyzed",
    percent: "100%",
  },
  {
    label: "Telegram Premium",
    percent: "10%",
  },
  {
    label: "OG Status Confirmed",
    percent: "70%",
  },
];

export default LoadingCommon;
