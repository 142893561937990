import React from "react";
import { NavFrame, NavigationContainer, NavigationList } from "./styled";
import { NavLink } from "react-router-dom";

const Navigation = () => {
  return (
    <NavigationContainer>
      <NavigationList>
        {navigationData.map((item, index) => {
          return (
            <li key={index}>
              <NavLink to={item.link}>
                <NavFrame>
                  <figure>
                    <img
                      width={36}
                      height={36}
                      src={item.icon}
                      alt="icon"
                      loading="lazy"
                    />
                  </figure>
                </NavFrame>
                <p>{item.name}</p>
              </NavLink>
            </li>
          );
        })}
      </NavigationList>
    </NavigationContainer>
  );
};

const navigationData = [
  {
    id: 1,
    name: "Earn",
    link: "/earn",
    icon: "/img/Navigation/navigation_1.svg",
  },
  {
    id: 2,
    name: "Top 10",
    link: "/leaderboard",
    icon: "/img/Navigation/navigation_2.svg",
  },
  {
    id: 3,
    name: "Game",
    link: "/game",
    icon: "/img/Navigation/navigation_3.svg",
  },
  {
    id: 4,
    name: "Shop",
    link: "/shop",
    icon: "/img/Navigation/navigation_5.svg",
  },
  {
    id: 5,
    name: "Friends",
    link: "/friends",
    icon: "/img/Navigation/navigation_4.svg",
  },
];

export default Navigation;
