import { Dispatch, createSlice } from "@reduxjs/toolkit";
import { ONI_API } from "../../services/api";
import { RULE_GAME } from "../../constant";

interface DriverState {
  getPoints: any;
  leaderBoard: any[];
  infoUser: any;
}

const initialState: DriverState = {
  getPoints: 0,
  leaderBoard: [],
  infoUser: null,
};

const pointSlice = createSlice({
  name: "pointSlice",
  initialState,
  reducers: {
    getpoints(state, action) {
      state.getPoints = action.payload;
    },
    getLeaderBoadrdSuccess(state, action) {
      state.leaderBoard = action.payload;
    },
    getInfoUserSuccess(state, action) {
      state.infoUser = action.payload;
    },
  },
});

export function getDataPoint(data: any) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(pointSlice.actions.getpoints(data));
    } catch (error) {
      console.log(error);
    }
  };
}

// export function getDataLeaderBoard() {
//   return async (dispatch: Dispatch) => {
//     try {
//       const { data } = await ONI_API.getLeaderBoard();
//       const listTop = [
//         {
//           img: "",
//         },
//         {
//           img: "",
//         },
//         {
//           img: "",
//         },
//         {
//           img: "",
//         },
//         {
//           img: "",
//         },
//       ];
//       const newList = data.map((item: any, index: number) => {
//         return {
//           img: listTop[index].img,
//           name: item.username,
//           point: item.point,
//         };
//       });

//       dispatch(pointSlice.actions.getLeaderBoadrdSuccess(newList));
//     } catch (error) {
//       console.log(error);
//     }
//   };
// }

export function getDataInfoUser() {
  return async (dispatch: Dispatch) => {
    try {
      const { data } = await ONI_API.getInfoUser();
      let pointOfLevel = 0;
      switch (data.level) {
        case 0:
          pointOfLevel = RULE_GAME.EXP.lv1;
          break;
        case 1:
          pointOfLevel = RULE_GAME.EXP.lv2;
          break;
        case 2:
          pointOfLevel = RULE_GAME.EXP.lv3;
          break;
        case 3:
          pointOfLevel = RULE_GAME.EXP.lv4;
          break;
        case 4:
          pointOfLevel = RULE_GAME.EXP.lv5;
          break;
        case 5:
          pointOfLevel = RULE_GAME.EXP.lv6;
          break;
        case 6:
          pointOfLevel = RULE_GAME.EXP.lv7;
          break;
        default:
          pointOfLevel = RULE_GAME.EXP.lv1;
          break;
      }
      dispatch(
        pointSlice.actions.getInfoUserSuccess({
          ...data,
          currentPointNextLevel: pointOfLevel,
        })
      );
    } catch (error) {
      console.log(error);
    }
  };
}

const pointReducer = pointSlice.reducer;

export default pointReducer;
