import styled from "styled-components";

export const NavigationContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  padding-bottom: 12px;
  &::before {
    content: "";
    position: absolute;
    top: -18px;
    left: 0;
    background: url("/img/Navigation/navigation_decor.png") no-repeat center /
      100% auto;
    width: 100%;
    height: 19px;
    pointer-events: none;
  }
  &:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    background: #373737;
    filter: drop-shadow(0px -32px 88.6px rgba(74, 222, 104, 0.65));
    width: 100%;
    height: 100%;
    z-index: -1;
    pointer-events: none;
  }
`;
export const NavFrame = styled.div`
  position: relative;
  margin-bottom: 15px;
  &::before,
  &::after {
    content: "";
    position: absolute;
    background: no-repeat center / 100% auto;
    left: 50%;
    top: 50%;
    margin-top: -10px;
    margin-left: -36px;
    width: 73px;
    height: 44px;
    pointer-events: none;
  }
  &::before {
    background-image: url("/img/Navigation/space_frame.png");
  }
  &::after {
    background-image: url("/img/Navigation/space_frame_hover.png");
    opacity: 0;
  }
  figure {
    position: relative;
    z-index: 5;
    img {
      transition: all .15s linear;
    }
    &::before {
      content: "";
      position: absolute;
      background: url("/img/Navigation/navigation_decor_effect.png") no-repeat center / 100% auto;
      width: 94px;
      height: 77px;
      left: -27px;
      bottom: -5px;
      opacity: 0;
      transition: all .25s cubic-bezier(0.215, 0.610, 0.355, 1);
    }
  }
`;
export const NavigationList = styled.ul`
  position: relative;
  display: flex;
  align-items: center;
  background-color: #373737;
  padding: 0 10px 10px;
  /* &::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100px;
    filter: drop-shadow(0px -32px 88.6px rgba(74, 222, 104, 0.65));;
  } */
  & > li {
    width: 25%;
    margin-top: -15px;
    & > a {
      display: flex;
      flex-direction: column;
      align-items: center;
      -webkit-tap-highlight-color: transparent;
      &.active {
        figure {
          img {
            transform: translateY(-10px) scale(1.5);
          }
          &::before {
            opacity: .7;
          }
        }
        & > p {
          color: #fff;
        }
        ${NavFrame} {
          &::before {
            opacity: 0;
          }
          &::after {
            opacity: 1;
          }
        }
      }
      & > p {
        font-family: Segoe_Bold;
        font-size: 14px;
        color: #727272;
        transition: all .15s linear;
      }
    }
  }
`;

