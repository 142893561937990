import { useSelector } from "react-redux";
import ProgressFrame from "../Common/ProgressBar";
import {
  HeaderContainer,
  HeaderInfo,
  HeaderVisual,
  VisualBlock,
} from "./styled";
// import eruda from "eruda";
import { ContextProviderWrapper } from "../Context";
import { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { convertBigNumberToText } from "../../utils/convertBigNumber";
// eruda.init();

const Header = () => {
  const { infoUser } = useSelector((state: any) => state.user);
  const { energy, setEnergy, point, setPoint } = useContext(
    ContextProviderWrapper
  )!;
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    // Energy refresh 1 every 1ms
    const intervalEngryPoint = setInterval(() => {
      setEnergy((prev: any) => Math.min(prev + 1, 1000));
    }, 1000);

    return () => {
      clearInterval(intervalEngryPoint);
    };
  }, []);

  // get energy user
  useEffect(() => {
    if (infoUser) {
      setEnergy(infoUser?.energy);
      setPoint(infoUser?.points);
    } else if (!infoUser) {
      navigate("/");
    }
  }, [infoUser, pathname]);

  return (
    <HeaderContainer>
      <HeaderInfo>
        <div>
          <figure>
            <img
              width={43}
              height={57}
              src={`${
                infoUser?.avatar_url
                  ? infoUser?.avatar_url
                  : "/img/Header/avatar.png"
              }`}
              alt="icon"
              loading="lazy"
            />
          </figure>
        </div>
        <div>
          <p>{infoUser?.fullname}</p>
          <ProgressFrame
            stateLoading={false}
            isShowNumber={true}
            isProgress={infoUser?.exp}
            currentLevel={infoUser?.level}
          />
        </div>
      </HeaderInfo>
      <HeaderVisual>
        <VisualBlock>
          <figure>
            <img
              width={27}
              height={28}
              src="/img/Header/visual_icon_1.png"
              alt="icon"
              loading="lazy"
            />
          </figure>
          {/* <p>{formatNumberMega(point)}</p> */}
          <p>{convertBigNumberToText(Number(point))}</p>
        </VisualBlock>
        <VisualBlock>
          <figure>
            <img
              width={27}
              height={28}
              src="/img/Header/visual_icon_2.png"
              alt="icon"
              loading="lazy"
            />
          </figure>
          <p>
            {energy} <span>/{infoUser?.energy_limit}</span>
          </p>
        </VisualBlock>
      </HeaderVisual>
    </HeaderContainer>
  );
};

export default Header;
