import styled from "styled-components";

export const TitleContainer = styled.h2`
  text-align: center;
  position: relative;
  z-index: 1;
  &:before {
    position: absolute;
    content: "";
    top: 50%;
    margin-top: -29px;
    left: 0;
    width: 100%;
    height: 45px;
    background: url("/img/Common/title_icn_02.png") center / cover no-repeat;
    z-index: -1;
  }
  span {
    color: #fff;
    text-align: center;
    -webkit-text-stroke-width: 4px;
    -webkit-text-stroke-color: var(--Native-text_color, #000);
    font-size: 20px;
    font-weight: 900;
    position: relative;
    padding-right: 50px;
    font-family: Segoe_Bold;
    paint-order: stroke fill;
    &:after {
      position: absolute;
      content: "";
      top: 50%;
      margin-top: -24px;
      right: 0;
      background: url("/img/Common/title_icn.svg") center / cover no-repeat;
      width: 45px;
      height: 46px;
    }
  }
`;

