import React, { useEffect, useState, useMemo } from "react";
import { GameProgressBar } from "./styled";

interface ProgressFrameProps {
  stateLoading: boolean;
  isShowNumber: boolean;
  isProgress: number;
  currentLevel: number;
}

const ProgressFrame: React.FC<ProgressFrameProps> = ({
  stateLoading,
  isShowNumber,
  isProgress,
  currentLevel,
}) => {
  const [loadingProgress, setLoadingProgress] = useState(0);

  // Define the required EXP for each level
  const EXP_LEVELS = useMemo(() => [0, 500000, 1000000, 3000000, 7000000, 15000000, 40000000, 100000000], []);

  useEffect(() => {
    if (stateLoading) {
      const interval = setInterval(() => {
        setLoadingProgress((prev) =>
          Math.min(prev + Math.floor(Math.random() * 40), 100)
        );
      }, 300);
      return () => clearInterval(interval);
    }
  }, [stateLoading]);

  // Calculate the progress percentage based on current EXP and level
  const calculateProgress = useMemo(() => {
    const currentLevelExp = EXP_LEVELS[currentLevel] || 0;
    const nextLevelExp = EXP_LEVELS[currentLevel + 1] || currentLevelExp;
    if (isProgress <= currentLevelExp) {
      return 0;
    }
    const expInCurrentLevel = isProgress - currentLevelExp;
    const expRequiredForNextLevel = nextLevelExp - currentLevelExp;
    const progress = Math.max(0, Math.min(100, (expInCurrentLevel / expRequiredForNextLevel) * 100));
    return progress;
  }, [currentLevel, isProgress, EXP_LEVELS]);

  const progress = stateLoading ? loadingProgress : calculateProgress;

  return (
    <GameProgressBar Progress={progress}>
      {isShowNumber && <p>{currentLevel}</p>}
      <span
        style={{
          width: `${progress}%`,
        }}
      />
    </GameProgressBar>
  );
};

export default ProgressFrame;
