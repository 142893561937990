import { instance } from "../instance";

export const ONI_API = {
  getInfoToken(contract: any) {
    return instance.get(`/token/get_token_info?address=${contract}`);
  },
  simulateCreatePool(params: any) {
    return instance.post(`/dex/pools/simulate_create_pool`, params);
  },
  confirmSimulateCreatePool(params: any) {
    return instance.post(`/dex/liquidity/provide`, params);
  },
  getAllPools(params: any) {
    return instance.post(
      `/dex/pools/balances?load_lp_accounts=${params.load_lp_accounts}`
    );
  },
  getAllAssets(params: any) {
    return instance.get(`/assets?is_conmunity=${params}`);
  },
  getPoolDetail(params: any, address: any) {
    if (address) {
      return instance.get(
        `/pools/detail?pool_address=${params}&wallet_address=${address}`
      );
    } else {
      return instance.get(`/pools/detail?pool_address=${params}`);
    }

    // return instance.get(`/pools/detail?pool_address=${params}`);
  },
  addNewAssetToken(params: any) {
    return instance.post(`/assets/create`, params);
  },
  checkTokenInPools(params: any) {
    return instance.get(
      `/pools/check?token0_address=${params.token0_address}&token1_address=${params.token1_address}`
    );
  },
  searchToken(params: any) {
    return instance.post(`/token/search`, params);
  },
  checkStatusSwap(params: any) {
    return instance.get(
      `/transactions/status?wallet_address=${params.wallet_address}&query_id=${params.query_id}`
    );
  },
  submitFavoriteToken(params: any) {
    return instance.post(`/assets/favorite`, params);
  },
  loginWithTele(param: any) {
    return instance.post("/auth/connect-telegram", param);
  },
  sendNumOfClick(param: any) {
    return instance.post("/points/click", param);
  },
  getInfoUser() {
    return instance.get(`/users/info`);
  },
  getLeaderBoard(page: number, perPage: number) {
    return instance.get(`/users/leaderboard?pagination[page]=${page}&pagination[perPage]=${perPage}`);
  },
  // task
  getTask() {
    return instance.get(`/tasks`);
  },
  doTask(id: string) {
    return instance.get(`/tasks/${id}/do`);
  },
  claimTask(id: string) {
    return instance.get(`/tasks/${id}/claim`);
  },
  //Check In
  getDailyInfor() {
    return instance.get(`tasks/checkin-info`);
  },
  getPointCheckin() {
    return instance.get(`tasks/checkin`);
  },
  //get point invite friend
  getPointInviteFriend() {
    return instance.get(`points/point-friend`);
  },
  // get list friends
  getListFriends() {
    return instance.get(`users/friends`);
  },
  //claim point invite friend
  claimPointFriends() {
    return instance.get(`users/claim`);
  },
  // get list skin
  getListSkin() {
    return instance.get(`skins`);
  },
  buySkin(id: string) {
    return instance.get(`skins/${id}/buy`);
  },
  selectSkin(id: string) {
    return instance.get(`skins/${id}/select`);
  },
};
