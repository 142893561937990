import React, { createContext, ReactNode, useState } from "react";
import { useMediaQuery } from "react-responsive";

interface StoreContextValue {
  isDesktop: any;
  isTablet: any;
  isMobile: any;
  energy: any;
  setEnergy:any;
  point: any;
  setPoint: any;
}
interface Props {
  children: ReactNode;
}
export const ContextProviderWrapper = createContext<StoreContextValue | null>(
  null
);
export const ContextProvider: React.FC<Props> = ({ children }: Props) => {
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  const isTablet = useMediaQuery({ maxWidth: 1023, minWidth: 768 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [energy, setEnergy] = useState<any>()
  const [point, setPoint] = useState<any>()
  return (
    <ContextProviderWrapper.Provider
      value={{
        isDesktop,
        isTablet,
        isMobile,
        energy,
        setEnergy,
        point,
        setPoint
      }}
    >
      {children}
    </ContextProviderWrapper.Provider>
  );
};
