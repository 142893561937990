import React from 'react'
import { EmptyDataContainer } from './styled'
import { Empty } from 'antd'

const EmptyData = () => {
  return (
    <EmptyDataContainer>
      <Empty />
    </EmptyDataContainer>
  )
}

export default EmptyData