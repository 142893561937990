import { Dispatch, createSlice } from "@reduxjs/toolkit";
import { ONI_API } from "../../services/api";

interface DriverState {
  infoUser: any;
  getListFriend: any;
  getListSkin: any;
  getLeaderBoard: any;
  getListMission: any;
  getDailyCheckin: any;
  getPointInviteFriend: any;
}

const initialState: DriverState = {
  infoUser: null,
  getListFriend: null,
  getListSkin: null,
  getLeaderBoard: null,
  getListMission: null,
  getDailyCheckin: null,
  getPointInviteFriend: null,
};

const userInfo = createSlice({
  name: "userInfo",
  initialState,
  reducers: {
    getInfoUser(state, action) {
      state.infoUser = action.payload;
    },
    getListFriend(state, action) {
      state.getListFriend = action.payload;
    },
    getListSkin(state, action) {
      state.getListSkin = action.payload;
    },
    getLeaderBoard(state, action) {
      state.getLeaderBoard = action.payload;
    },
    getListMission(state, action) {
      state.getListMission = action.payload;
    },
    getDailyCheckin(state, action) {
      state.getDailyCheckin = action.payload;
    },
    getPointInviteFriend(state, action) {
      state.getPointInviteFriend = action.payload;
    },
  },
});

export function getDataInfoUser() {
  return async (dispatch: Dispatch) => {
    try {
      const res = await ONI_API.getInfoUser();
      if (res) {
        dispatch(userInfo.actions.getInfoUser(res?.data));
      } else {
        console.error("No user data found");
      }
    } catch (error) {
      console.error("Error fetching user info:", error);
      window.location.reload();
    }
  };
}

export function getDataListFriends() {
  return async (dispatch: Dispatch) => {
    try {
      const res = await ONI_API.getListFriends();
      if (res) {
        dispatch(userInfo.actions.getListFriend(res?.data?.data));
      }
    } catch (error) {
      console.log(error);
    }
  };
}

// get list skin
export function getDataListSkin() {
  return async (dispatch: Dispatch) => {
    try {
      const res = await ONI_API.getListSkin();
      if (res) {
        dispatch(userInfo.actions.getListSkin(res?.data?.data));
      }
    } catch (error) {
      console.log(error);
    }
  };
}

// get leader board
export function getDataLeaderBoard() {
  return async (dispatch: Dispatch) => {
    try {
      const res = await ONI_API.getLeaderBoard(1, 200);
      if (res) {
        dispatch(userInfo.actions.getLeaderBoard(res?.data?.data));
      }
    } catch (error) {
      console.log(error);
    }
  };
}

// get list mission
export function getDataListMission() {
  return async (dispatch: Dispatch) => {
    try {
      const res = await ONI_API.getTask();
      if (res) {
        dispatch(userInfo.actions.getListMission(res?.data?.data));
      }
    } catch (error) {
      console.log(error);
    }
  };
}

// get daily checkin
export function getDataDailyCheckin() {
  return async (dispatch: Dispatch) => {
    try {
      const res = await ONI_API.getDailyInfor();
      if (res) {
        dispatch(userInfo.actions.getDailyCheckin(res?.data?.dailyCheckin));
      }
    } catch (error) {
      console.log(error);
    }
  };
}

// get point invite friend
export function getDataPointInviteFriend() {
  return async (dispatch: Dispatch) => {
    try {
      const res = await ONI_API.getPointInviteFriend();
      if (res) {
        dispatch(userInfo.actions.getPointInviteFriend(res?.data));
      }
    } catch (error) {
      console.log(error);
    }
  };
}

const userReducer = userInfo.reducer;

export default userReducer;
