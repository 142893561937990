import styled from "styled-components";

export const GameContainer = styled.div`
  margin: 0 -15px;
  height: calc(100vh - 170px);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: #142518;
`;
export const GameWrapper = styled.div`
  position: relative;
`;
export const GameImageMain = styled.div`
  max-width: 450px;
  width: 100%;
  margin: 0 auto;
  img {
    width: 100%;
  }
`;
export const GameImageUser = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 227px;
  height: 253px;
  bottom: 241px;
  img {
    transition: all 0.1s ease-in-out;
  }
  .img-main {
    position: absolute;
    z-index: 1;
  }
  .left-hand,
  .right-hand {
    position: absolute;
    bottom: -20px;
    width: 91px;
    height: 91px;
    overflow: hidden;
  }
  .left-hand {
    left: 0;
  }
  .right-hand {
    right: 0;
  }
  .right-hand-different,
  .left-hand-different {
    z-index: 2;
    bottom: -25px;
    img {
      width: 57px;
      height: 67px;
    }
  }
  .left-hand-different {
    left: 5px;
  }
  .right-hand-different {
    right: -25px;
  }
`;
export const GameButtonClick = styled.button`
  position: absolute;
  bottom: 100px;
  left: 50%;
  margin-left: -94px;
  background: unset;
  border: none;
  outline: none;
  padding: 0;
  overflow: hidden;
  -webkit-tap-highlight-color: transparent;
  z-index: 1;
  &:focus {
    background: unset;
  }
  img {
    transition: all 0.1s ease;
    height: 154px;
  }
`;
export const PointTouchStart = styled.div`
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: flowPoint 1s cubic-bezier(0.23, 1, 0.320, 1);
  text-shadow: 2.436px 2.436px 0px #4ec575;
  font-weight: bold;
  font-size: 45px;
  opacity: 0;
  pointer-events: none;
  @keyframes flowPoint {
    0% {
      transform: -100px;
    }
    10% {
      opacity: 1;
    }
    50% {
      opacity: 0.8;
    }
    70% {
      opacity: 0.4;
    }
    100% {
      transform: translateY(-300%);
    }
  }
`;
export const Earth = styled.div`
  position: absolute;
  bottom: 193px;
  left: 50%;
  transform: translateX(-50%);
  mix-blend-mode: darken;
  background: linear-gradient(180deg, #e3ffe5 0%, #4ec575 100%);
  height: 58px;
  overflow: hidden;
  width: 100%;
  pointer-events: none;
  img {
    width: 100%;
  }
`;
export const GamePoint = styled.span`
  position: absolute;
  z-index: 1;
  top: 30%;
  transform: translateY(-50%);
  right: 70px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 59px;
  height: 45px;
  &:before {
    position: absolute;
    content: "";
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: url("/img/Game/game_icn_01.png") center / cover no-repeat;
    width: 59px;
    height: 45px;
    z-index: -1;
  }
  .point {
    color: var(--Mint-Green-600, #16a333);
    text-align: center;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #fff;
    font-family: Segoe_Bold;
    font-size: 33.421px;
    letter-spacing: -2.339px;
    position: absolute;
    top: 45%;
    left: 56%;
    transform: translate(-50%, -50%);
  }
`;
export const GraphicBlock = styled.div`
  position: absolute;
  bottom: 100px;
  right: 20px;
  z-index: 1;
  &:after {
    position: absolute;
    content: "";
    bottom: 7px;
    left: 50%;
    margin-left: -23px;
    width: 42px;
    height: 26px;
    background: url("/img/Game/game_img_06.png") center / cover no-repeat;
    z-index: -1;
  }
`;
export const Graphic = styled.button`
  background: unset;
  border: none;
  outline: none;
  padding: 0;
  overflow: hidden;
  -webkit-tap-highlight-color: transparent;
  z-index: 1;
  transform: translateY(40px) rotateZ(6deg);
  height: 126px;
  display: flex;
  align-items: flex-start;
  &:focus {
    background: unset;
  }
  &:active {
    transform: translateY(40px) rotateZ(-6deg);
  }
`;
