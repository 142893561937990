import React, { useState } from "react";
import { ButtonGo } from "../styled";
import { formatNumberMega } from "../../../utils/formatNumber";
import { Spin } from "antd";
import { toast } from "react-hot-toast";
import { getDataInfoUser, getDataListMission } from "../../../redux/features/userSlice";
import { ONI_API } from "../../../services/api";
import { useDispatch } from "../../../redux/store";

const ButtonClaimMisson = ({ item }: any) => {
  const dispatch = useDispatch();
  const [loadingClaim, setLoadingClaim] = useState(false);

  // handle claim task
  const handleTaskAction = async (item: any) => {
    setLoadingClaim(true);
    try {
      if (item?.user_task.length > 0 && item.user_task[0].status === "CLAIM") {
        const res = await ONI_API.claimTask(item.task_id);
        if (res) {
          toast.success("Task claimed successfully");
          await dispatch(getDataInfoUser());
          await dispatch(getDataListMission());
        }
      } else {
        toast.error("Task cannot be claimed");
      }
      setLoadingClaim(false);
    } catch (error) {
      console.error(error);
      toast.error("Action failed");
      setLoadingClaim(false);
    }
  };

  return (
    <>
      <ButtonGo className="is-claim" onClick={() => handleTaskAction(item)}>
        {loadingClaim ? <Spin /> : `Claim ${formatNumberMega(item.point)}`}
      </ButtonGo>
    </>
  );
};

export default ButtonClaimMisson;
