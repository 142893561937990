import React, { useState } from "react";
import {
  ButtonMission,
  ModalMissionContainer,
  ModalMissionDescription,
  ModalMissionPoint,
  ModalMissionTitle,
  ModalMissionWrapper,
} from "./styled";
import "./modalMisson.css";
import { ONI_API } from "../../../services/api";
import { getDataListMission } from "../../../redux/features/userSlice";
import { useDispatch } from "../../../redux/store";

const ModalMission = ({ handleCancelMission, itemMission }: any) => {
  const dispatch = useDispatch();

  //handle do task
  const handleDoTask = async (id: string) => {
    handleCancelMission();
    try {
      setTimeout(async () => {
        const res = await ONI_API.doTask(id);
        if (res) {
          await dispatch(getDataListMission());
        }
      }, 10000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ModalMissionContainer>
      <ModalMissionWrapper>
        <ModalMissionTitle>{itemMission.name}</ModalMissionTitle>
        <ModalMissionDescription>
          {itemMission.description ? itemMission.description : "No Description"}
        </ModalMissionDescription>
        <ModalMissionPoint>{itemMission.point}</ModalMissionPoint>
        <ButtonMission
          href={itemMission.url}
          target="_blank"
          onClick={() => handleDoTask(itemMission.task_id)}
        >
          Go
        </ButtonMission>
      </ModalMissionWrapper>
    </ModalMissionContainer>
  );
};

export default ModalMission;
