import styled from "styled-components";
import { ButtonClaim } from "../styled";

export const ModalClaimContainer = styled.div`
  position: relative;
  min-height: 400px;
  ${ButtonClaim} {
    font-size: 20px;
    font-family: Poppins;
    height: 47px;
    width: 224px;
    position: relative;
    left: -10px;
  }
`;
export const ModalClaimImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;
export const ModalClaimTitle = styled.h2`
  color: var(--Mint-Green-600, #16a333);
  text-align: center;
  font-size: 28.405px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -1.136px;
  line-height: 1;
  transform: rotate(4.361deg);
  span {
    letter-spacing: 0;
    display: block;
  }
`;
export const ModalClaimContent = styled.div`
  min-height: 307px;
  padding-top: 56px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 1;
`;
