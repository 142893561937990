import React, { useEffect, useState } from "react";
import {
  FriendContainer,
  FriendListButton,
  FriendPoint,
  FriendText,
} from "./styled";
import { LayoutCommon } from "../../layout/styled";
import TitleCommon from "../../components/Common/Title";
import { BlockLeaderboard, ListLeaderboard } from "../Leaderboard/styled";
import { ONI_API } from "../../services/api";
import { useInitData } from "@tma.js/sdk-react";
import toast from "react-hot-toast";
import WebApp from "@twa-dev/sdk";
import _ from "lodash";
import { formatNumberMega } from "../../utils/formatNumber";
import { Spin } from "antd";
import { useDispatch } from "../../redux/store";
import { getDataInfoUser, getDataPointInviteFriend } from "../../redux/features/userSlice";
import EmptyData from "../../components/Common/EmptyData";
import { useSelector } from "react-redux";

interface PointInterface {
  pointInvite: number;
}
export interface ListFriendInterface {
  fullname: string;
  point: number;
}

const Friends = () => {
  const { getListFriend, getPointInviteFriend, infoUser } = useSelector((state: any) => state.user);
  const dataUserTele: any = useInitData();
  const [pointInviteFriend, setPointInviteFriend] =
    useState<PointInterface | null>(getPointInviteFriend);
  const [loading, setLoading] = useState(false);
  const {
    initData: { user },
  } = dataUserTele;
  const dispatch = useDispatch();

  //handle invite friend
  const handleInviteFriend = (type: string) => {
    try {
      const link = `https://t.me/ONIClickerBot/Game?startapp=${infoUser?.referral_code}`;
      const shareLink = `https://t.me/share/url?url=${encodeURIComponent(
        link
      )}`;
      if (type === "copy") {
        navigator.clipboard.writeText(link);
        toast.success("Copied");
      } else {
        WebApp.openTelegramLink(shareLink);
      }
    } catch (error) {
      console.log("====================================");
      console.log("handleInviteFriend err", error);
      console.log("====================================");
    }
  };

  //claim point friend
  const handleClaimPointFriend = async () => {
    setLoading(true);
    try {
      const res = await ONI_API.claimPointFriends();
      if (res) {
        toast.success("Success");
        await dispatch(getDataPointInviteFriend());
        await dispatch(getDataInfoUser());
      }
    } catch (error: any) {
      toast.error(error.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  // handle button actions
  const handleButtonClick = (text: string) => {
    switch (text) {
      case "Claim Reward":
        handleClaimPointFriend();
        break;
      case "Invite your friend":
        handleInviteFriend("invite");
        break;
      case "Copy":
        handleInviteFriend("copy");
        break;
      default:
        console.log("Unknown action");
        break;
    }
  };

  //get point invite friend
  useEffect(() => {
    setPointInviteFriend(getPointInviteFriend);
  }, [getPointInviteFriend]);

  return (
    <FriendContainer className="animate__animated animate__fadeIn">
      <LayoutCommon className="block-info">
        <div className="image-decor">
          <img src="/img/Friends/friends_bg_03.png" width={107} alt="" />
        </div>
        <TitleCommon text="Play Together" />
        <FriendText>
          It’s more fun to play with your friend. Invite & play together
        </FriendText>
        <FriendPoint>
          <span>
            {_.isEmpty(pointInviteFriend)
              ? 0
              : formatNumberMega(pointInviteFriend?.pointInvite)}
          </span>
        </FriendPoint>
        <FriendListButton>
          {listButton.map((item: any, index: number) => (
            <li key={index}>
              <button onClick={() => handleButtonClick(item.text)}>
                {item.text === "Claim Reward" && loading ? <Spin /> : item.text}
              </button>
            </li>
          ))}
        </FriendListButton>
      </LayoutCommon>
      <LayoutCommon className="block-friends">
        <TitleCommon text="Invited Friends" />
        <BlockLeaderboard>
          <ListLeaderboard>
            {getListFriend?.length > 0 ? (
              getListFriend?.map((item: any, index: number) => (
                <li key={index}>
                  <div className="content-left">
                    <span className="position">{index + 1}</span>
                    <h3
                      className={`name ${item.isPremium ? "is-premium" : ""}`}
                    >
                      {item.fullname}
                    </h3>
                  </div>
                  <div className="content-right">
                    <span className="level">{item.level ? item.level : 0}</span>
                    <span className="point">{item.point ? formatNumberMega(item.point) : 0}</span>
                  </div>
                </li>
              ))
            ) : (
              <EmptyData />
            )}
          </ListLeaderboard>
        </BlockLeaderboard>
      </LayoutCommon>
    </FriendContainer>
  );
};

const listButton = [
  {
    text: "Claim Reward",
  },
  {
    text: "Invite your friend",
  },
  {
    text: "Copy",
  },
];

export default Friends;
