import styled from "styled-components";
import { TitleContainer } from "../../components/Common/Title/styled";

export const ShopContainer = styled.div`
  .block-dressing {
    background: url("/img/Shop/block_bg.png") center / cover no-repeat;
    min-height: 240px;
    ${TitleContainer} {
      text-align: left;
      margin-bottom: 10px;
    }
  }
  .block-item {
    height: calc(100vh - 530px);
    overflow: auto;
    ${TitleContainer} {
      padding: 10px 0;
      margin-bottom: 10px;
    }
  }
`;
export const BlockDressing = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;
export const BlockDressingLeft = styled.div`
  border-radius: 18px;
  border: 2.607px solid #bbffb2;
  background: linear-gradient(180deg, #e0ffda 0%, #fff 100%);
  position: relative;
  z-index: 1;
  width: 152px;
  border-radius: 18px;
  text-align: center;
  overflow: hidden;
  padding: 10px 18px;
  min-height: 165px;
  &:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("/img/Shop/shop_img_03.png") center / cover no-repeat;
    z-index: -1;
  }
`;
export const BlockDressingImage = styled.div`
  .title-skin {
    color: #fff;
    text-align: center;
    -webkit-text-stroke-width: 4px;
    -webkit-text-stroke-color: var(--Native-text_color, #000);
    font-family: Segoe_Bold;
    font-size: 14px;
    font-weight: 900;
    margin-bottom: 8px;
    paint-order: stroke fill;
    line-height: 1.5;
  }
  .content-image {
    width: 95px;
    height: 95px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 5px;
    border-radius: 18px;
    border: 2.607px solid #4ade68;
    background: linear-gradient(270deg, #bbf7c6 18.34%, #4ade68 83.31%);
    img {
      width: 48px;
      height: 70px;
      object-fit: cover;
    }
  }
`;
export const BlockDressingButton = styled.button`
  color: #fff;
  text-align: center;
  text-shadow: 0px 1.245px 0px #000;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--Native-text_color, #000);
  font-family: Poppins;
  font-size: 16px;
  font-weight: 900;
  outline: none;
  background: url("/img/Shop/bg_button.png") center / cover no-repeat;
  width: 165px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 20px;
  margin: 0 auto;
  -webkit-tap-highlight-color: transparent;
`;
export const BlockDressingRight = styled.div`
  position: relative;
  top: 9px;
`;

export const BlockItem = styled.div`
  /* overflow: auto; */
`;
export const BlockItemList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px 10px;
  li {
    -webkit-tap-highlight-color: transparent;
    width: calc(100% / 3 - 10px);
    text-align: center;
    position: relative;
    .check-icon {
      position: absolute;
      top: -12px;
      right: -12px;
    }
    .title-skin {
      margin-bottom: 0;
    }
  }
`;
