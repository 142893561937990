import styled from "styled-components";

export const GameProgressBar = styled.div<{ Progress?: any }>`
  position: relative;
  width: 100%;
  height: 14px;
  border-radius: 15px;
  background: #166527;
  /* overflow: hidden; */
  z-index: 5;
  margin-left: 8px;
  span {
    position: relative;
    display: block;
    height: 14px;
    background: #22c544;
    transition: all 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    overflow: hidden;
    border-radius: 15px;
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 7px;
      top: 0;
      left: 0;
      background-color: #81EE97;
      border-radius: 15px;
    }
  }
  p {
    position: absolute;
    left: -10px;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    transition: all 0.15s linear;
    z-index: 5;
    background: url('/img/Header/progress_star.png') no-repeat center / 100% auto;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
