import React from "react";
import { TitleContainer } from "./styled";

interface TitleInterface {
  text: string;
}
const TitleCommon = ({ text }: TitleInterface) => {
  return (
    <TitleContainer>
      <span>{text}</span>
    </TitleContainer>
  );
};

export default TitleCommon;
