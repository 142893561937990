import styled from "styled-components";
import { TitleContainer } from "../../components/Common/Title/styled";

export const EarnContainer = styled.div`
  .block-top {
    ${TitleContainer} {
      text-align: left;
      margin-bottom: 10px;
      &:before {
        content: none;
      }
      span {
        &:after {
          content: none;
        }
      }
    }
  }
  .block-bottom {
    ${TitleContainer} {
      padding: 10px 0 15px;
    }
  }
`;
export const EarnHeading = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 5px;
  .day {
    color: #000;
    text-align: center;
    font-size: 14px;
    font-weight: 900;
    letter-spacing: -0.56px;
    font-family: Segoe_Bold;
    position: relative;
    top: -4px;
  }
  .button {
    text-align: center;
    font-family: Segoe_Bold;
    font-size: 11px;
    letter-spacing: -0.44px;
    position: relative;
    border: unset;
    outline: none;
    z-index: 1;
    text-align: center;
    padding-left: 15px;
    color: #fff;
    text-shadow: 0px 3px 0px #000;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #000;
    paint-order: stroke fill;
    &:before {
      position: absolute;
      content: "";
      top: 50%;
      left: 46%;
      width: 73.875px;
      height: 20.704px;
      z-index: -1;
      transform: skew(-26deg) translate(-50%, -50%);
      border-radius: 3px;
      background: linear-gradient(180deg, #ceffd1 0%, #4ec575 100%);
      box-shadow: 0px 2.239px 3.247px 0px rgba(97, 255, 115, 0.44) inset;
    }
    &:disabled {
      color: #9b9b9b;
      -webkit-text-stroke-width: 0;
      text-shadow: unset;
      -webkit-text-stroke-color: unset;
      &:before {
        background: #c1c1c1;
        box-shadow: unset;
      }
    }
  }
`;
export const EarnBlockCheckin = styled.div`
  height: calc(100vh - 470px);
  overflow: auto;
`;
export const EarnCheckin = styled.ul`
  display: flex;
  align-items: center;
  gap: 2px;
  margin-bottom: 15px;
  li {
    flex-shrink: 0;
    width: 30px;
    height: 6px;
    background-color: #c1c1c1;
    transform: skew(-26deg);
    border-radius: 2px;
    &.checked {
      background-color: #000;
    }
  }
`;
export const BlockPoint = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  .total-point {
    position: relative;
    left: -11px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 3px;
    border-radius: 0px 69px 69px 0px;
    background: var(--Mint-Green-300, #81ee97);
    color: #fff;
    text-shadow: 0px 3px 0px #000;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #000;
    font-family: Segoe_Bold;
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: 100%; /* 16px */
    width: 119.2px;
    height: 33px;
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 3px;
      img {
        position: relative;
        top: 3px;
      }
    }
  }
`;

export const ButtonClaim = styled.button`
  border: none;
  background-color: unset;
  outline: none;
  color: #fff;
  text-align: center;
  text-shadow: 0px 1.245px 0px #000;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 900;
  position: relative;
  width: 171px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 17px;
  z-index: 1;
  &:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    background: url("/img/Earn/earn_bg_01.png") center / cover no-repeat;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
`;

export const TextTop = styled.p`
  color: #757575;
  text-align: center;
  font-family: Segoe_Bold;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: -0.48px;
`;

export const ListMission = styled.ul`
  position: relative;
  &:after {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    width: 100%;
    height: 2px;
    box-shadow: 0px 1px 0.8px 0px #166527 inset;
    background-color: #bbffb2;
    opacity: 0.2;
  }
  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 11px 10px;
    position: relative;
    &:after {
      position: absolute;
      content: "";
      bottom: 0;
      right: 0;
      width: 100%;
      height: 2px;
      box-shadow: 0px 1px 0.8px 0px #166527 inset;
      background-color: #bbffb2;
      opacity: 0.2;
    }
    &.is-done {
      opacity: 0.5;
      &:before {
        position: absolute;
        content: "";
        top: 50%;
        left: 50%;
        margin-left: -68px;
        margin-top: -24px;
        background: url("/img/Earn/earn_img_01.png") center / cover no-repeat;
        width: 137px;
        height: 48px;
      }
    }
  }
  .content-left {
    max-width: 60%;
  }
  .title {
    color: #000;
    font-family: Segoe_Bold;
    font-size: 15px;
    font-weight: 900;
  }
  .text {
    color: #757575;
    font-family: Segoe_Bold;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: -0.48px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }
`;
export const ButtonGo = styled.button`
  background: unset;
  border: unset;
  color: #fff;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  text-shadow: 0px 1.245px 0px #000;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 900;
  position: relative;
  z-index: 1;
  min-width: 84px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:before,
  &:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    z-index: -1;
    transition: all 0.26s ease;
  }
  &:before {
    background: url("/img/Earn/button_bg.png") center / cover no-repeat;
  }
  &:after {
    opacity: 0;
    background: url("/img/Earn/button_bg_02.png") center / cover no-repeat;
  }
  &.is-claim {
    min-width: 130px;
    &:after {
      opacity: 1;
    }
  }
  &:disabled {
    opacity: 1;
  }
  .ant-spin-dot-item {
    color: #81EE97;
  }
`;
