import styled from "styled-components";
import { TitleContainer } from "../../components/Common/Title/styled";
import { ListLeaderboard } from "../Leaderboard/styled";

export const FriendContainer = styled.div`
  .block-info {
    .image-decor {
      position: absolute;
      right: 5px;
      top: 0;
      z-index: -1;
    }
    ${TitleContainer} {
      text-align: left;
      margin-bottom: 10px;
      &:before {
        content: none;
      }
      span {
        padding-right: 36px;
        &:after {
          background-image: url("/img/Friends/friends_icn_01.svg");
          width: 27px;
          height: 27px;
          margin-top: -10px;
        }
      }
    }
  }
  .block-friends {
    height: calc(100vh - 450px);
    overflow: auto;
    ${TitleContainer} {
      padding: 10px 0;
      margin-bottom: 10px;
    }
    ${ListLeaderboard} {
      li {
        .name {
          position: relative;
          padding-right: 18px;
          &:after {
            position: absolute;
            content: "";
            top: 5px;
            right: 0;
            background: url("/img/Friends/friends_icn_04.png") center / cover
              no-repeat;
            width: 13px;
            height: 13px;
          }
          &.is-premium {
            &:after {
              background-image: url("/img/Friends/friends_icn_03.png");
            }
          }
        }
      }
    }
  }
`;
export const FriendText = styled.p`
  color: #757575;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: -0.48px;
  margin-bottom: 10px;
`;
export const FriendPoint = styled.div`
  text-align: center;
  margin-bottom: 10px;
  span {
    color: #fff;
    text-shadow: 0px 3.798px 0px #000;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #000;
    font-size: 25.322px;
    font-weight: 900;
    position: relative;
    padding-left: 45px;
    &:before {
      position: absolute;
      content: "";
      top: 50%;
      left: 0;
      margin-top: -24px;
      background: url("/img/Friends/friends_icn_02.svg") center / cover
        no-repeat;
      width: 43px;
      height: 50px;
    }
  }
`;
export const FriendListButton = styled.ul`
  display: flex;
  justify-content: center;
  gap: 5px;
  button {
    background: unset;
    border: none;
    color: #fff;
    text-align: center;
    text-shadow: 0px 1.245px 0px #000;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #000;
    font-family: Poppins;
    font-size: 0.8rem;
    font-weight: 900;
    padding: .5rem .6rem;
    border-radius: 20px;
    position: relative;
    overflow: hidden;
    z-index: 1;
    &:before,
    &:after {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 18.474px;
      transition: all 0.26s ease;
      z-index: -1;
    }
    &:before {
      background: url("/img/Friends/friends_bg_01.png") center / cover no-repeat;
    }
    &:after {
      background: url("/img/Friends/friends_bg_02.png") center / cover no-repeat;
      opacity: 0;
    }
    /* &:hover {
      &:after {
        opacity: 1;
      }
    } */
  }
`;
