import React, { useEffect, useState } from "react";
import {
  BlockPoint,
  ButtonClaim,
  ButtonGo,
  EarnBlockCheckin,
  EarnCheckin,
  EarnContainer,
  EarnHeading,
  ListMission,
  TextTop,
} from "./styled";
import { LayoutCommon } from "../../layout/styled";
import TitleCommon from "../../components/Common/Title";
import { Modal } from "antd";
import ModalClaim from "./Modal";
import "./index.css";
import moment from "moment";
import { formatNumberMega } from "../../utils/formatNumber";
import EmptyData from "../../components/Common/EmptyData";
import ModalMission from "./ModalMission";
import { useDispatch } from "../../redux/store";
import { useSelector } from "react-redux";
import ButtonClaimMisson from "./ButtonClaim";
import ButtonDoTask from "./ButtonDoTask";

interface UserTaskStatus {
  status: string;
}

interface TaskItem {
  task_id: string;
  name: string;
  description: string | null;
  point: number;
  url: string;
  file_id: string | null;
  created_at: string;
  updated_at: string;
  thumbnail_info: {
    file_path: string;
  } | null;
  user_task: UserTaskStatus[];
}

const Home = () => {
  const { getListMission, getDailyCheckin } = useSelector(
    (state: any) => state.user
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenMission, setIsModalOpenMission] = useState(false);
  const [dataTask, setDataTask] = useState<any>(getListMission);
  const [dataDailyCheckin, setDataDailyCheckin] =
    useState<any>(getDailyCheckin);
  const [claimCheckin, setClaimCheckin] = useState(false);
  const [itemMission, setItemMission] = useState<any>();
  const dispatch = useDispatch();

  //modal claim daily checkin
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  //modal mission
  const showModalMission = (item: any) => {
    setIsModalOpenMission(true);
    setItemMission(item);
  };

  const handleOkMission = () => {
    setIsModalOpenMission(false);
  };

  const handleCancelMission = () => {
    setIsModalOpenMission(false);
  };

  useEffect(() => {
    setDataTask(getListMission);
    if (dataTask) {
      const sortedTasks = [...getListMission].sort(
        (a: TaskItem, b: TaskItem) => {
          const aStatus = a?.user_task?.[0]?.status;
          const bStatus = b?.user_task?.[0]?.status;

          if (aStatus === "COMPLETED") return 1;
          if (bStatus === "COMPLETED") return -1;
          return 0;
        }
      );
      setDataTask(sortedTasks);
    }
  }, [getListMission]);

  //get data daily checkin
  // const getDataDailyCheckin = async () => {
  //   const res = await ONI_API.getDailyInfor();
  //   setDataDailyCheckin(res?.data?.dailyCheckin);
  // };

  useEffect(() => {
    setDataDailyCheckin(getDailyCheckin);
  }, [getDailyCheckin]);

  const totalDays = 6;
  const listDay = Array.from({ length: totalDays }, (_, index) => ({
    checkin: index < dataDailyCheckin?.checkin_count,
  }));

  //handle checkin
  const canCheckin = () => {
    if (!dataDailyCheckin?.last_checkin) {
      return true;
    }
    const lastCheckin = moment(dataDailyCheckin?.last_checkin);
    const currentTime = moment();
    const diffInHours = currentTime.diff(lastCheckin, "hours");

    return diffInHours >= 24;
  };

  useEffect(() => {
    const isAllowed = canCheckin();
    setClaimCheckin(!isAllowed);
  }, [dataDailyCheckin]);

  // data point
  const listPoint = [
    {
      point: 10000,
    },
    {
      point: 20000,
    },
    {
      point: 30000,
    },
    {
      point: 40000,
    },
    {
      point: 50000,
    },
    {
      point: 60000,
    },
    {
      point: 70000,
    },
  ];

  const checkinCount = dataDailyCheckin?.checkin_count;
  const claimedPoint = checkinCount
    ? checkinCount === 0
      ? 10000
      : checkinCount === 7
      ? 70000
      : listPoint[checkinCount]?.point || 0
    : 10000;

  return (
    <>
      <EarnContainer className="animate__animated animate__fadeIn">
        <LayoutCommon className="block-top">
          <TitleCommon text="Daily Earn" />
          <EarnHeading>
            <span className="day">
              Day {checkinCount ? Math.min(checkinCount + 1, 7) : 1}
            </span>
            <EarnCheckin>
              {listDay.map((item: any, index: number) => (
                <li key={index} className={item.checkin ? "checked" : ""}></li>
              ))}
              <button
                disabled={checkinCount === 7 ? false : true}
                className="button"
              >
                Special Gift
              </button>
            </EarnCheckin>
          </EarnHeading>
          <BlockPoint>
            <div className="total-point">
              <span>
                <img src="/img/Earn/earn_icn_01.svg" alt="" />
                {formatNumberMega(claimedPoint)}
              </span>
            </div>
            <ButtonClaim onClick={showModal} disabled={claimCheckin}>
              Claim Reward
            </ButtonClaim>
          </BlockPoint>
          <TextTop>Remember to come back & earn tomorrow’s reward.</TextTop>
        </LayoutCommon>
        <LayoutCommon className="block-bottom">
          <TitleCommon text="Mission" />
          <EarnBlockCheckin>
            <ListMission>
              {dataTask?.length > 0 ? (
                dataTask?.map((item: any, index: number) => {
                  const status = item?.user_task?.[0]?.status;
                  return (
                    <li
                      key={index}
                      className={status === "COMPLETED" ? "is-done" : ""}
                    >
                      <div className="content-left">
                        <h3 className="title">{item.name}</h3>
                        {item.description && (
                          <p className="text">
                            {item.description && item.description}
                          </p>
                        )}
                      </div>
                      {item.user_task.length > 0 && status === "CLAIM" ? (
                        <ButtonClaimMisson item={item} />
                      ) : status === "COMPLETED" ? (
                        <ButtonGo className="claimed">Claimed</ButtonGo>
                      ) : (
                        <ButtonDoTask
                          item={item}
                          handleCancelMission={handleCancelMission}
                        />
                      )}
                    </li>
                  );
                })
              ) : (
                <EmptyData />
              )}
            </ListMission>
          </EarnBlockCheckin>
        </LayoutCommon>
      </EarnContainer>
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        footer={false}
        className="modal-claim"
      >
        <ModalClaim
          dataDailyCheckin={dataDailyCheckin}
          listPoint={listPoint}
          claimedPoint={claimedPoint}
          handleCancel={handleCancel}
        />
      </Modal>
      {/* Modal Mission */}
      <Modal
        open={isModalOpenMission}
        onOk={handleOkMission}
        onCancel={handleCancelMission}
        centered
        footer={false}
        className="modal-mission"
      >
        <ModalMission
          handleCancelMission={handleCancelMission}
          itemMission={itemMission}
        />
      </Modal>
    </>
  );
};

export default Home;
