import styled from "styled-components";

export const LayoutContainer = styled.div``;
export const ButtonCommon = styled.button``;
export const WrapperOutlet = styled.div`
  padding: 20px 15px;
`;
export const LayoutCommon = styled.div`
  position: relative;
  width: calc(100% - 30px);
  padding: 15px;
  min-height: 100px;
  border-radius: 12px;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.88) 0%,
      rgba(255, 255, 255, 0.88) 100%
    ),
    linear-gradient(180deg, #6ea380 0%, #629262 100%);
  box-shadow: -4px 0px 0px 0px rgba(0, 0, 0, 0.46) inset,
    4px 0px 0px 0px rgba(0, 0, 0, 0.25) inset;
  backdrop-filter: blur(3px);
  &:first-child {
    &::before,
    &::after {
      content: "";
      position: absolute;
      background: no-repeat center / 100% auto;
      width: 14px;
      height: 46px;
      bottom: -25px;
      z-index: 5;
    }
    &::before {
      left: 10px;
      background-image: url('/img/Common/lock_note_left.png');
    }
    &::after {
      right: 10px;
      background-image: url('/img/Common/lock_note_right.png');
    }
  }
  &:last-child {
    &::before,
    &::after {
      content: "";
      position: absolute;
      background: no-repeat center / 100% auto;
      width: 12px;
      height: 38px;
      top: -20px;
      z-index: 5;
    }
    &::before {
      left: 0;
      background-image: url('/img/Common/lock_note2_left.png');
    }
    &::after {
      right: 0;
      background-image: url('/img/Common/lock_note2_right.png');
    }
  }
  &:not(:last-child) {
    margin-bottom: 5px;
    z-index: 5;
  }
  /* &:not(:first-child) {
    &::before,
    &::after {
      content: none;
    }
  } */
`;
export const DashboardContainerDesKtop = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  .img-qr-game {
    max-width: 100%;
    margin: 0 auto;
    img {
      width: 100%;
      margin-top: -130px;
    }
  }
  h1 {
    position: absolute;
    top: 65%;
    color: #fff;
    text-align: center;
    color: var(--Neutral-50, #f7f7f8);
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #737373;
    font-family: Segoe_Bold;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 21.25px */
    text-transform: uppercase;
  }
`