import styled from "styled-components";
import { BlockDressingButton } from "../Shop/styled";

export const YearContainer = styled.div`
  background: url('/img/Year/year_bg.png') center / cover no-repeat;
  height: 100vh;
`
export const YearWrapper = styled.div`
  padding-top: 50px;
  ${BlockDressingButton} {
    position: fixed;
    bottom: 20px;
    right: 20px;
  }
`
export const YearBlockTop = styled.div`
  text-align: center;
  .content-top {
    min-height: 119px;
  }
  .number {
    color: #FFF;
    text-shadow: 0px 0px 25px rgba(119, 255, 101, 0.50), 0px -4px 0px #138834;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #0A5800;
    font-family: Poppins;
    font-size: 66px;
    font-weight: 900;
    line-height: 1;
    margin-bottom: -5px;
    display: block;
  }
  .text-year {
    color: #EBFFEF;
    text-align: center;
    text-shadow: 0px 0px 53.374px rgba(48, 255, 141, 0.50);
    font-family: Rubik;
    font-size: 24px;
    font-weight: 900;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  .text-account {
    color: #003F14;
    font-size: 14px;
    font-weight: 800;
    text-transform: uppercase;
    border-radius: 0px 21px 21px 16px;
    background: var(--Mint-Green-300, #81EE97);
    backdrop-filter: blur(2px);
    max-width: 317px;
    margin: 0 auto;
    padding: 7px 6px;
  }
`
