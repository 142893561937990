import { useEffect, useState } from "react";
import ProgressFrame from "../../components/Common/ProgressBar";
import TitleCommon from "../../components/Common/Title";
import { HeaderInfo } from "../../components/Header/styled";
import { LayoutCommon } from "../../layout/styled";
import {
  BlockLeaderboard,
  LeaderboardContainer,
  LeaderboardHeading,
  LeaderboardRank,
  ListLeaderboard,
} from "./styled";
import { useSelector } from "react-redux";
import { formatNumberMega } from "../../utils/formatNumber";
import InfiniteScroll from "react-infinite-scroll-component";
import EmptyData from "../../components/Common/EmptyData";
import { convertBigNumberToText } from "../../utils/convertBigNumber";

const Leaderboard = () => {
  const { getLeaderBoard } = useSelector((state: any) => state.user);
  const [dataLeaderboard, setDataleaderboard] = useState(getLeaderBoard);
  const { infoUser } = useSelector((state: any) => state.user);
  const [hasMore, setHasMore] = useState(true);
  const [items, setItems] = useState<any>(dataLeaderboard);

  useEffect(() => {
    const initialItems = dataLeaderboard?.slice(0, 10);
    setItems(initialItems);
  }, [dataLeaderboard]);

  // fetch more data
  const fetchMoreData = () => {
    if (items.length >= dataLeaderboard?.length) {
      setHasMore(false);
      return;
    }
    // Append 20 more items
    setTimeout(() => {
      const moreItems = dataLeaderboard?.slice(items.length, items.length + 10);
      setItems((prevItems: any) => prevItems.concat(moreItems));
    }, 500);
  };

  return (
    <LeaderboardContainer className="animate__animated animate__fadeIn">
      <LayoutCommon>
        <div className="image-decor">
          <img src="/img/Leaderboard/loaderboard_icn_02.png" width={49} height={49} alt="decor" />
        </div>
        <LeaderboardHeading>
          <HeaderInfo>
            <div>
              <figure>
                <img
                  width={43}
                  height={57}
                  src={infoUser?.avatar_url ? infoUser?.avatar_url : "/img/Header/avatar.png"}
                  alt="icon"
                  loading="lazy"
                />
              </figure>
            </div>
            <div>
              <p className="name-leaderboard">{infoUser?.fullname}</p>
              <ProgressFrame
                stateLoading={false}
                isShowNumber={true}
                isProgress={infoUser?.exp}
                currentLevel={infoUser?.level}
              />
            </div>
          </HeaderInfo>
          <LeaderboardRank>
            <img src="/img/Leaderboard/loaderboard_icn_01.png" width={43} height={37} alt="rank" />
            <div className="content-rank">
              <p className="text">Your rank</p>
              <span className="position">{infoUser?.rank}</span>
            </div>
          </LeaderboardRank>
        </LeaderboardHeading>
      </LayoutCommon>
      <LayoutCommon className="block-leaderboard">
        <TitleCommon text="Leaderboard" />
        <BlockLeaderboard>
          <ListLeaderboard>
            {dataLeaderboard?.length > 0 ? (
              <InfiniteScroll
                dataLength={items.length}
                next={fetchMoreData}
                hasMore={hasMore}
                loader={<h4>Loading...</h4>}
                height={`calc(100vh - 420px)`}
                endMessage={
                  <p style={{ textAlign: "center" }}>
                    <b>Yay! You have seen it all</b>
                  </p>
                }
              >
                {items.map((item: any, index: number) => (
                  <li key={index}>
                    <div className="content-left">
                      <span className="position">{index + 1}</span>
                      <h3 className="name">{item.fullname}</h3>
                    </div>
                    <div className="content-right">
                      <span className="level">
                        {item.level ? item?.level : 0}
                      </span>
                      <span className="point">
                        {item.total_exp ? convertBigNumberToText(Number(item.total_exp)) : 0}
                      </span>
                    </div>
                  </li>
                ))}
              </InfiniteScroll>
            ) : (
              <EmptyData />
            )}
          </ListLeaderboard>
        </BlockLeaderboard>
      </LayoutCommon>
    </LeaderboardContainer>
  );
};

export default Leaderboard;
