import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { Root } from "./Root";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import 'animate.css';

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Root />
  // <BrowserRouter>
  //   <App />
  // </BrowserRouter>
);

reportWebVitals();
