import React, { useState } from "react";
import { ButtonGo } from "../styled";
import { getDataListMission } from "../../../redux/features/userSlice";
import { useDispatch } from "../../../redux/store";
import { ONI_API } from "../../../services/api";
import { Spin } from "antd";
import WebApp from "@twa-dev/sdk";

const ButtonDoTask = ({ item, handleCancelMission }: any) => {
  const dispatch = useDispatch();
  const [loadingDoTask, setLoadingDoTask] = useState(false);

  //handle open new windown
  const handleOpenNewWindow = () => {
    WebApp.openLink(item.url);
    setTimeout(()=>{
      handleDoTask(item.task_id);
    },500)
    // WebApp.showConfirm(`Open this link: ${item.url}?`, (confirmed) => {
    //   if (confirmed) {
    //   } else {
    //     console.log('User canceled the action');
    //   }
    // });
  }

  //handle do task
  const handleDoTask = async (id: string) => {
    handleCancelMission();
    setLoadingDoTask(true);
    try {
      setTimeout(async () => {
        const res = await ONI_API.doTask(id);
        if (res) {
          await dispatch(getDataListMission());
          setLoadingDoTask(false);
        }
      }, 10000);
    } catch (error) {
      console.log(error);
      setLoadingDoTask(false);
    }
  };

  return (
    <>
      <ButtonGo onClick={() => handleOpenNewWindow()} disabled={loadingDoTask ? true : false}>
        {loadingDoTask ? <Spin style={{color: "#fff"}} /> : "Go"}
      </ButtonGo>
    </>
  );
};

export default ButtonDoTask;
